import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import '../../assets/js/chosen.jquery.js';
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-game-home',
  templateUrl: './game-home.component.html',
  styleUrls: ['./game-home.component.css'],
})
export class GameHomeComponent implements OnInit {
  days: any[] = [];
  industries: any = [];
  selectedIndustry: any;
  loading: boolean = true;
  currentDay: any;
  game_role: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
  }

  ngOnInit() {
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  changeIndustry() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/update_industry',
        { industry_id: this.selectedIndustry, type: 'home' },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        if (res.status) {
          console.log('Industry Changed!!');
        }
      });
  }

  loadData() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/day_tracking',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        this.days = res.data;
        res.data.forEach((element) => {
          if (element.attempt == 'current') {
            this.currentDay = element.day;
          }
        });
        this.game_role = res.game_role;
        this.http
          .get('https://admin.careerforward.ca/api/industries?type=home', {
            headers: this.reqHeaders(),
          })
          .subscribe((res: any) => {
            console.log(res);
            if (res.status) {
              this.industries = res.data;
              res.data.forEach((industry) => {
                if (industry.active) {
                  this.selectedIndustry = industry.id;
                  setTimeout(() => {
                    (<HTMLInputElement>(
                      document.querySelector('#game')
                    )).value = this.selectedIndustry;
                  }, 0);
                }
                this.loading = false;
              });
            }
          });
      });
  }

  openGame(item) {
    this.selectedIndustry = (<HTMLInputElement>(
      document.querySelector('#game')
    )).value;
    if (item) {
      if (item.attempt != 'no') {
        if (this.selectedIndustry) {
          this.changeIndustry();
          this.router.navigate(
            this.game_role
              ? ['/game-confirmation', item.day]
              : ['/game-rules', item.day]
          );
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'You need to select industry first.',
          });
        }
      }
    } else {
      if (!this.currentDay) {
        Swal.fire({
          icon: 'warning',
          title: 'Game not played on Saturdays and Sundays',
        });
      } else {
        if (this.selectedIndustry) {
          this.changeIndustry();
          this.router.navigate(
            this.game_role ? ['/game-confirmation'] : ['/game-rules']
          );
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'You need to select industry first.',
          });
        }
      }
    }
  }

  scrollBtn() {
    let div = document.querySelector('.game-home');
    if (div.scrollHeight > screen.height && window.pageYOffset < 20) {
      return true;
    } else {
      return false;
    }
  }
  ngAfterViewInit() {
    document.addEventListener('scroll', () => {
      // console.log(window.pageYOffset);
    });
  }
}
