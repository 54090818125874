<div class="content-wrapper">
  <div class="page-game-rules">
    <div class="heading">This is how you play the game!</div>
    <ul>
      <li mb-3>
        <b>There will be no activities assigned on Saturday and Sunday.</b>
      </li>
      <li>You have 10 days to complete the game.</li>
      <li>
        For maximum points, answer the questions and complete the activities on
        the day they are released.
      </li>
      <li>Disruptor questions will force you to change jobs.</li>
      <li>Answer 5 questions each day and complete up to 5 activities.</li>
      <li>You will lose points if you fall behind.</li>
      <li>
        The focus of this game is to learn. Not knowing the right answer is
        perfectly okay. That's what the activities are for!
      </li>
      <li>
        You have one opportunity to answer each question. There are no re-dos!
      </li>
      <li>Each response generates an activity.</li>
    </ul>
    <div *ngIf="day != 'menu'">
      <input
        type="checkbox"
        class="mx-1"
        [(ngModel)]="check"
        (click)="updateCheck()"
      />
      Don't show this again
      <a
        [routerLink]="
          day ? ['/game-confirmation', day] : ['/game-confirmation']
        "
        replaceUrl
        class="btn mt-2"
        >Continue</a
      >
    </div>
  </div>
</div>
