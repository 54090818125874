<div class="content-wrapper">
  <div class="lmi-page">
    <div class="tabs">
      <a
        (click)="screen = 'outlooks'"
        [ngClass]="{ active: screen == 'outlooks' }"
        >Outlook</a
      >
      <a (click)="showMap()" [ngClass]="{ active: screen == 'maps' }"
        >Maps</a
      >
    </div>

    <div *ngIf="screen == 'outlooks'">
      <div class="lmi-content-box">
        <h4>Local Employment Potential Information</h4>
        <p>
          {{example}}...
          <a href="javascript:" class="popup-link" (click)="openPopup()"
            >Read More</a
          >
        </p>
      </div>

      <div class="lmi-card">
        <div class="card-header">3 - year outlook</div>
        <div class="card-body">
          <ul class="degrees">
            <li><span class="star-rating-3"></span>= “Good”</li>
            <li><span class="star-rating-2"></span>= “Fair”</li>
            <li><span class="star-rating-1"></span>= “Limited”</li>
            <li style="font-size: 12px;"><span class="star-rating-0"></span>= “Undetermined”</li>
          </ul>
          <ul class="list">
            <li class="text" *ngFor="let prov of lmiPotential">
              <span class="text">{{provinesMapper[prov.pruid].name}}</span><span class="star-rating-{{prov.star}}"></span>
            </li>
          </ul>
          <a class="btn" (click)="openOutLookPopup()">Outlook Methodology</a>
        </div>
      </div>

      <div class="lmi-card">
        <div class="card-header">Suggested Occupation 3-year Outlook</div>
        <div class="card-body">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <th width="40%">Occupation</th>
              <th width="30%">Provincial</th>
              <th width="30%">Regional</th>
            </tr>
            <tr *ngFor="let noc of relatedNocResults | keyvalue" >
              <td>{{noc.key}}</td>
              <td>{{noc.value.province}}<span class="star-rating-{{noc.value.provincial}}"></span></td>
              <td>{{noc.value.region}}<span class="star-rating-{{noc.value.reginal}}"></span></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="lmi-map" *ngIf="screen == 'maps'">
    <agm-map #gm
    class="lmi-map"
  [latitude]="lat"
  [longitude]="lng"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false">

  <agm-marker 
      *ngFor="let m of markers; let i = index"
      (markerClick)="markerClicked(m); gm.lastOpen?.close(); gm.lastOpen = infoWindow"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [markerDraggable]="m.draggable"
  >
      
    <agm-info-window #infoWindow>
      <div>
        <strong>{{pointedMarker.erName ? pointedMarker.erName : pointedMarker.fullName}}</strong>
        <strong>({{pointedMarker.provCode}})</strong>
      </div>
      <div class="noc-data">
        <p class="noc-name">{{provinceData.noc_label}}</p><span class="noc-box">NOC {{provinceData.noc}}</span>
      </div>
      <div class="outlook-div">
        <span class="star-rating-{{markerStar}}"></span><span class="outlook-type">{{outLook}}</span>
      </div>
      <p class="info-window" [innerHtml]="markerDescripton">
      </p>
    </agm-info-window>
    
  </agm-marker>

</agm-map>
  </div>
  <div class="popup" *ngIf="screen == 'outlooks'">
    <div class="popup-box">
      <span class="close" (click)="closeMenu()"></span>

      <h5>Local Employment Potential Information</h5>
      <div [innerHtml]="description"></div>
    </div>
  </div>

  <div class="popup outlook-info" *ngIf="screen == 'outlooks'">
    <div class="popup-box">
      <span class="close" (click)="closeMenuOutlook()"></span>
      <div class="col-sm-10 col-sm-offset-1">
        <h3>2017-2019 Occupational Outlooks Methodology</h3>
        <p>The future forecast and current conditions for an occupation can vary based on location or due to changes in the economy, technology, or demand for a product or service.</p>
        <p><strong>How are employment outlooks are displayed?</strong></p>
        <p>Employment outlooks are displayed in on this site, where possible, for each occupation in each province, territory and economic region. </p>
        <p>The employment outlooks consist of 2 parts:</p>
        <ul>
              <li>A star rating (out of three) to indicate whether the outlooks in this occupation are Good (3 stars), Fair (2 stars) or Limited (1 star)</li>
              <li>A text providing additional details about the occupations, as well as information about the key labour market indicators that helped determine the star rating.</li>
        </ul>
        <p><strong>For what time period are the employment outlooks valid?</strong></p>
        <p>The employment outlooks represent a 3-year period. For example, current employment outlooks cover the period of 2017 through 2019.</p>
        <p><strong>When are the employment outlooks updated?</strong></p>
        <p>Employment outlooks are reviewed and updated on an annual basis, usually in December of the first year of the projections.</p>
        <p><strong>What are the main sources of information used in determining the outlooks?</strong></p>
        <p>The main sources of information used in developing the employment outlooks include the following; however other pertinent data sources may also be consulted:</p>
        <ol>
            <li>Census / National Household Survey (Statistics Canada)</li>
            <li>Labour Force Survey (Statistics Canada)</li>
            <li>Canadian Occupational Projections System (ESDC)</li>
            <li>E-Data from the Conference Board of Canada</li>
            <li>Employment Insurance administrative data (ESDC)</li>
        </ol>
        <p><strong>Which occupational classification is used to determine employment outlooks?</strong></p>
        <p>Employment outlooks are based on the National Occupational Classification (NOC) 2011, as this depends on the classification in use in the data consulted during the analysis.</p>
        <p><strong>Who sets the employment outlooks available and how are they determined?</strong></p>
        <p>The outlooks at the provincial and economic region level are determined using a standard approach developed by Employment and Social Development Canada (ESDC) and the Service Canada Regional LMI Network. The selected approach was also reviewed by various experts from organizations such as Statistics Canada, the Bank of Canada, and the Department of Finance. For the territories, a simplified version of the methodology was developed in consultation with territorial stakeholders.</p>
        <p>Both approaches involve a mechanical analysis of key labour market indicators. The results of this analysis are then validated by economists from the Service Canada Regional Labour Market Analysis Directorates (LMAD), to take into consideration the reliability and accuracy of the data used (for example, quality indicators and other known issues affecting the data), as well as additional information, such as labour market news, demographic trends, data from sectoral or professional organizations, and other complementary available survey and administrative data.</p>
        <p><strong>What are the key labour market indicators used to determine employment outlooks?</strong></p>
        <p>The key labour market indicators include:</p>
        <ol>
            <li><strong>Employment growth rate</strong>, which is measured by impacts of industrial growth on employment in which the occupation is found. Trends affecting the progression of employment by occupation within these industries may also be considered.</li>
            <li><strong>Index of experienced unemployed workers available at the beginning of the projection period</strong>, which is measured by the proportion of Employment Insurance beneficiaries over total employment, adjusted by the average length of benefits.</li>
            <li><strong>Attrition rate</strong> which is measured by the proportion of projected retirements, deaths and other attrition over total employment. This rate is related to the age structure within an occupation.</li>
            <li><strong>Net needs</strong> which are measured by the sum of employment growth and attrition, less the experienced unemployed workers available at the beginning of the period, divided by the projected average employment for the occupation.</li>
        </ol>
        <p><strong>Outlook Definitions</strong></p>
        <ul>
            <li><i class="fa fa-star outlook-star" aria-hidden="true"></i><i class="fa fa-star outlook-star" aria-hidden="true"></i><i class="fa fa-star outlook-star" aria-hidden="true"></i><strong style="margin-left: 0.25em;">Good</strong>: This outlook is attributed to occupations where the short- to medium-term potential for employment is better than average in the region, when compared with other occupations. This situation is most favourable for job seekers and may be associated with a lack of qualified or experienced labour, high turnover, low unemployment rates, above average employment growth, high projected retirement rates, very specific job requirements, and/or a lack of interest by the available labour force, etc.</li>
            <li><i class="fa fa-star outlook-star" aria-hidden="true"></i><i class="fa fa-star outlook-star" aria-hidden="true"></i><i class="far fa-star outlook-star" aria-hidden="true"></i><strong style="margin-left: 0.25em;">Fair</strong>:This outlook is attributed to occupations where the short- to medium-term potential for employment is comparable to the average for all occupations in the region. This situation is often associated with an average or greater demand for labour and a supply of qualified labour that is very similar to estimated needs.</li>
            <li><i class="fa fa-star outlook-star" aria-hidden="true"></i><i class="far fa-star outlook-star" aria-hidden="true"></i><i class="far fa-star outlook-star" aria-hidden="true"></i><strong style="margin-left: 0.25em;">Limited</strong>: This outlook is attributed to occupations where the short- to medium-term potential for employment is below average in the region, when compared with other occupations. This situation is often associated with slow or declining projected employment growth, weakness in related industry activity, and/or higher levels of unemployment, etc. This is a more challenging situation for job seekers, especially new entrants such as school-leavers and immigrants, and may indicate that the labour supply in the region exceeds the estimated number of employment opportunities for the outlook period.</li>
            <li><i class="far fa-star outlook-star star-0" aria-hidden="true"></i><i class="far fa-star outlook-star star-0" aria-hidden="true"></i><i class="far fa-star outlook-star star-0" aria-hidden="true"></i><strong style="margin-left: 0.25em;">Undetermined</strong>: This indicates that employment outlooks were not determined for this occupation. In some cases, employment outlooks may not have been determined for occupations where there was insufficient data or too few workers in the occupation within a specific region to determine an outlook, where employment opportunities in the occupations are often dependent on other factors such as appointment, election, talent or entrepreneurship, or where the types of jobs included within the occupation vary greatly from each other making it challenging to determine outlooks (for example: some occupations that begin with "Other"). </li>
        </ul>
        <p>Please note that these definitions are intended for information purposes only. Users are encouraged to use a variety of information from different sources to make career decisions, while also considering their interests, aptitudes, and personal competencies.</p>
        <p><strong>What are the data sources for the key facts?</strong></p>
        <p>At all levels of geography, where sufficient data exists, key facts include the following elements:</p>
        <ul>
            <li>Level of employment: estimated by Employment and Social Development Canada from Labour Force Survey data. When the provincial level of employment for an occupation falls below the dissemination thresholds for the Labour Force Survey, the level is established using data from the 2011 National Household Survey (Statistics Canada).</li>
            <li>Main industries of employment: estimated by Employment and Social Development Canada and Service Canada.</li>
        </ul>
        <p>At the provincial level, the following key facts are also displayed, where available:</p>
        <ul>
            <li>Full-time and Part-time employment: 2011 National Household Survey (Statistics Canada)</li>
            <li>Full-year and part-year employment: 2011 National Household Survey (Statistics Canada)</li>
            <li>Self-employment: 2011 National Household Survey (Statistics Canada)</li>
        </ul>
        <p><strong>Note</strong>: <i>In preparing these outlooks, the authors have taken care to provide clients with labour market information that is timely and accurate at the time of publication. Since labour market conditions are dynamic, some of the information presented may have changed since the outlooks were determined. Users are encouraged to also refer to other sources for additional information on the local economy and labour market. When consulting outlooks related to smaller occupations or more sparsely populated geographic regions, extra care is advised. Information contained does not necessarily reflect official policies of Employment and Social Development Canada.</i></p>
        <p>For further information, please <a href="mailto:NC-LMI-IMT-GD@hrsdc-rhdcc.gc.ca">contact the LMI team</a>.</p>
      </div>
      
    </div>
  </div>

  
</div>
