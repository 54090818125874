<div class="white-box" [ngClass]="{ small1: reset }">
  <div class="activity-wrapper">
    <h3 class="mt-3 mb-2">"Day {{ day }}" Activities</h3>
    <p class="text-center mt-0 mb-4" style="font-size: 0.8em">
      Tap on each to open
    </p>
    <ul>
      <li
        *ngFor="let activity of _activities"
        [ngClass]="{
          complete: activity.read_status,
          incomplete: !activity.read_status
        }"
      >
        <span (click)="openResource(activity)">
          {{ activity.activity_name }}
        </span>
      </li>
    </ul>
  </div>
</div>

<div class="reset" *ngIf="resetGame && day === '10'">
  <p class="text-center mt-0 mb-2" style="font-size: 0.7em">
    Your Game is Complete.
  </p>

  <div class="btn" (click)="resetGame()">Reset Game</div>
</div>

<!-- <div
  class="scroll-down-btn"
  [ngStyle]="{ display: scrollBtn() ? 'block' : 'none' }"
>
  <span></span>
  <span></span>
  <span></span>
</div> -->

<div class="popup">
  <div class="popup-box">
    <p class="mb-5">
      You are being redirected to the third-party website. In order to come back
      to the application, you need to tap on the back button of the device.
    </p>
    <div class="action">
      <button class="btn btn-yes my-2" type="button" (click)="redirect()">
        Proceed To Website
      </button>
      <p style="font-size: 14px" (click)="closePopup()">Cancel</p>
      <!-- <button class="btn btn-no my-2" type="button">Cancel</button> -->
    </div>
  </div>
</div>

<div class="popup-reset">
  <div class="popup-box">
    <p class="mb-5 text-center">
      Your game has besn reset successfully.
    </p>
    <div class="action">
      <button class="btn btn-yes my-2" type="button" (click)="closeResetPopup()">
        Proceed
      </button>
    </div>
  </div>
</div>
