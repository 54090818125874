<div class="content-wrapper">
  <div class="resources-page">
    <div class="block-resource">
      <a routerLink="/lmi-form">
        <span class="icon-circle icon-circle-lmi"></span>
        <span class="text">Employment Outlooks</span>
      </a>
    </div>
    <div class="block-resource">
      <a routerLink="/job-search">
        <span class="icon-circle icon-circle-search"></span>
        <span class="text">Job Search</span>
      </a>
    </div>
    <div class="block-resource">
      <a routerLink="/career-development">
        <span class="icon-circle icon-circle-career-development"></span>
        <span class="text">Career Development</span>
      </a>
    </div>
  </div>
</div>
