<div class="content-wrapper">
  <div class="job-search-page" *ngIf="!loading">
    <ul>
      <li *ngFor="let cat of categories">
        <a [routerLink]="['/career-development', cat.id]">
          <span
            [ngClass]="{ 'icon-circle': true, 'category-logo': cat.logo }"
            [ngStyle]="{
              'background-image': cat.logo
                ? 'url(' + cat.logo + ')'
                : 'url(assets/img/icon-circle-career-development.svg)'
            }"
          ></span>
          <span class="text">{{ cat.category_name }}</span>
        </a>
      </li>
    </ul>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
