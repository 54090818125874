import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.css'],
})
export class NeedHelpComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private menu: MenuService
  ) {
    this.menu.updateTitle('HELP');
  }

  ngOnInit() {}
}
