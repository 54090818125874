<div class="content-wrapper">
  <div class="notification-page d-flex flex-column">
    <div class="flex-grow-1">
      <div class="new-notifs mb-4">
        <strong class="mb-2 d-block" *ngIf="new_notifs.length > 0"
          >New Notifications</strong
        >
        <strong class="mb-2 d-block" *ngIf="new_notifs.length == 0">
          No New Notification
        </strong>
        <ul>
          <li *ngFor="let n of new_notifs">{{ n.description }}</li>
        </ul>
      </div>
      <div class="past-notifs mb-3">
        <strong class="mb-2 d-block" *ngIf="past_notifs.length > 0"
          >Past Notifications</strong
        >
        <ul>
          <li *ngFor="let n of past_notifs">{{ n.description }}</li>
        </ul>
      </div>
    </div>

    <button class="btn-clear" (click)="clearAllNotifs()">
      <span class="icon-clear"></span>
      <span class="text">Clear All</span>
    </button>

    <div class="bottom-block">
      <div class="box" *ngIf="announcement">
        <div [routerLink]="['/announcement', announcement.id]">
          <strong class="mr-1">{{ announcement.title }}:</strong>
          <p
            [line-truncation]="2"
            [innerHTML]="announcement.desc"
            [options]="{ ellipsis: '... <strong>READ MORE</strong>' }"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
