import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
})
export class EditProfileComponent implements OnInit {
  profileForm: FormGroup;
  imageSrc: string | ArrayBuffer = '';
  loading: boolean = true;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cookie: CookieService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('EDIT PROFILE');

    this.profileForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required]],
      profile_pic: [''],
      password: [''],
      password_confirmation: [''],
    });
  }

  get f() {
    return this.profileForm.controls;
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.http
      .get('https://admin.careerforward.ca/api/user/profile', {
        headers: this.reqHeaders(),
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.f.first_name.setValue(res.data.first_name);
          this.f.last_name.setValue(res.data.last_name);
          this.f.email.setValue(res.data.email);
          this.f.mobile_number.setValue(res.data.mobile_number);
          this.imageSrc = res.data.profile_pic;
          this.loading = false;
        }
      });
  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.profileForm.patchValue({ profile_pic: file });

      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }

  onSubmit() {
    console.log(this.profileForm.value);
    if (this.f.password.value != this.f.password_confirmation.value) {
      // this.f.password_confirmation.setErrors({'match': true});
      this.toastr.warning("PASSWORDS doesn't match", 'warning');
      return;
    } else if (this.profileForm.invalid) {
      for (const name in this.f) {
        if (this.f[name].invalid) {
          if (this.f[name].errors.required) {
            let field = name.split('_').join(' ').toUpperCase();
            this.toastr.warning(`"${field}" is required.`, 'warning');
          } else if (this.f[name].errors.email) {
            this.toastr.warning('"EMAIL" - Invalid Format', 'warning');
          }
        }
      }
    } else {
      let fd = new FormData();
      Object.keys(this.profileForm.value).forEach((key) => {
        fd.append(key, this.profileForm.value[key]);
      });
      this.http
        .post('https://admin.careerforward.ca/api/user/profile/update', fd, {
          headers: this.reqHeaders(),
        })
        .subscribe((res: any) => {
          console.log(res);
          if (res.status) {
            this.toastr.success('Profile updated', 'Success');
            let prevObj = JSON.parse(this.cookie.get('user'));
            // console.log(prevObj);
            let pic = '';
            if (res.message == 'Profile Updated') {
              pic = res.data[0].profile_pic;
            }
            this.cookie.set(
              'user',
              JSON.stringify({
                ...prevObj,
                first_name: this.f.first_name.value,
                last_name: this.f.last_name.value,
                profile_pic: pic,
                email: this.f.email.value,
                full_name:
                  this.f.first_name.value + ' ' + this.f.last_name.value,
              }),
              1,
              '/'
            );
            this.authService.updateUserDetails(
              JSON.parse(this.cookie.get('user'))
            );
          }
        });
    }
  }

  ngAfterViewInit() {
    const togglePassword = document.querySelector('#icon-password');
    const password = document.querySelector('#password');
    togglePassword.addEventListener('click', function (e) {
      const type =
        password.getAttribute('type') === 'password' ? 'text' : 'password';
      password.setAttribute('type', type);
      this.classList.toggle('active');
    });
    const toggleconfirmPassword = document.querySelector(
      '#icon-confirm-password'
    );
    const confirmPassword = document.querySelector('#confirm-password');
    toggleconfirmPassword.addEventListener('click', function (e) {
      const confirmType =
        confirmPassword.getAttribute('type') === 'password'
          ? 'text'
          : 'password';
      confirmPassword.setAttribute('type', confirmType);
      this.classList.toggle('active');
    });

    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          let tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }
}
