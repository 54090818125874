<div class="content-wrapper">
  <div class="activity-success-block" *ngIf="!loading">
    <span class="icon-check"></span>
    <h4>
      You have successfully answered the 5 questions. Complete the activities
      below to earn more points.
    </h4>
    <h5 class="mb-2">Your Activities</h5>
    <p class="text-center mt-0 mb-4" style="font-size: 0.8em">
      Tap on each to open
    </p>
    <ul>
      <li
        *ngFor="let activity of activities"
        [ngClass]="{
          complete: activity.read_status,
          incomplete: !activity.read_status
        }"
      >
        <span (click)="openResource(activity)">
          {{ activity.activity_name }}
        </span>
      </li>
    </ul>
  </div>
  <!-- <div
    class="scroll-down-btn"
    [ngStyle]="{ display: scrollBtn() ? 'block' : 'none' }"
  >
    <span></span>
    <span></span>
    <span></span>
  </div> -->
</div>

<div
  class="content-wrapper d-flex align-items-center justify-content-center"
  *ngIf="loading"
>
  <div class="spinner">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</div>

<div class="popup">
  <div class="popup-box">
    <p class="mb-5">
      You are being redirected to the third-party website. In order to come back
      to the application, you need to tap on the back button of the device.
    </p>
    <div class="action">
      <button class="btn btn-yes my-2" type="button" (click)="redirect()">
        Proceed To Website
      </button>
      <p style="font-size: 14px" (click)="closePopup()">Cancel</p>
      <!-- <button class="btn btn-no my-2" type="button">Cancel</button> -->
    </div>
  </div>
</div>
