import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }

  forgotPassword() {
    var email = (<HTMLInputElement>document.getElementById('email')).value;
    if (!email) {
      Swal.fire({
        title: 'Email is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else {
      this.http
        .post('https://admin.careerforward.ca/api/forget_password', {
          email: email,
        })
        .subscribe((response: any) => {
          if (response.status == true) {
            Swal.fire({
              text:
                'A mail has been sent to this account. Follow its instructions to continue.',
              allowOutsideClick: false,
            }).then((result) => {
              this.router.navigate(['/login']);
            });
          } else {
            Swal.fire({
              title: 'Invalid email.',
              allowOutsideClick: false,
            }).then((result) => {
              return false;
            });
          }
        });
    }
  }
}
