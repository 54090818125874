import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-activities-success',
  templateUrl: './activities-success.component.html',
  styleUrls: ['./activities-success.component.css'],
})
export class ActivitiesSuccessComponent implements OnInit {
  currentDay: any;
  selectedDay: any;
  loading: boolean = true;
  played: boolean = true;
  points: any;
  remaining_days: any = [];
  total_points = 0
  totalDaysHistory = []
  dayAlreadyOpen = false
  constructor(
    private router: Router,
    private menuService: MenuService,
    private cookie: CookieService,
    private http: HttpClient,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.selectedDay = this._route.snapshot.params.day;
    this.http
      .post(
        'https://admin.careerforward.ca/api/leaderboard',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          const myDetails = res.user_data;
          this.total_points = myDetails.p
        }
      });
    this.http
      .post(
        'https://admin.careerforward.ca/api/day_wise_points',
        { day: this.selectedDay },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((response: any) => {
        console.log(response);
        if (response.status) {
          this.points = response.data.total_points;

          this.http
            .post(
              'https://admin.careerforward.ca/api/day_tracking',
              {},
              {
                headers: this.reqHeaders(),
              }
            )
            .subscribe((res: any) => {
              console.log(res);
              if (res.status) {
                this.totalDaysHistory = res.data
                res.data.forEach((element) => {
                  if (element.attempt == 'current') {
                    this.currentDay = element.day;
                  }
                });
                this.http
                  .post(
                    'https://admin.careerforward.ca/api/remaining_activity_days',
                    {},
                    {
                      headers: this.reqHeaders(),
                    }
                  )
                  .subscribe((res1: any) => {
                    console.log(res1);
                    if (res1.status) {
                      this.remaining_days = res1.data.reamaining_day_qus;
                      this.totalDaysHistory.forEach(hisDay => {
                        if(hisDay.day === this.remaining_days[0]) {
                          if(hisDay.attempt === "yes") {
                            this.dayAlreadyOpen = true
                          }
                        }
                      })
                      this.loading = false;
                      this.http
                        .post(
                          'https://admin.careerforward.ca/api/completion_status',
                          { day: this.selectedDay },
                          {
                            headers: this.reqHeaders(),
                          }
                        )
                        .subscribe((res) => {
                          console.log(res);
                        });
                    }
                  });
              }
            });
        }
        let date = new Date(new Date().setDate(new Date().getDate() + 1));
        if (date.getDay() == 6 || date.getDay() == 7) {
          this.played = false;
        }
      });
  }

  resetGame() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/reset_game',
        {},
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        console.log(res);
        this.menuService.updateCoins(0);
        if (res.status) {
          this.openPopup()
        }
      });
  }

  openPopup() {
    document.querySelector('.popup').classList.add('active');
  }

  closePopup() {
    document.querySelector('.popup').classList.remove('active');
    this.router.navigate(['/home']);
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
