<div class="content-wrapper">
  <div class="header small">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="" width="270" />
    </div>
  </div>
  <div *ngIf="!loading" class="container">
    <div class="form-section">
      <div class="inner-wrap">
        <div class="icon-circle-block icon-login"></div>
        <form enctype="multipart/form-data" onsubmit="javascript:return false;">
          <div class="field">
            <div class="field-box">
              <input type="email" name="email" id="email" class="formControl" />
              <span class="icon-email"></span>
            </div>
            <label for="email">Email</label>
          </div>
          <div class="field mb-75">
            <div class="field-box">
              <input
                type="password"
                name="password"
                id="password"
                class="formControl field-password"
              />
              <span
                class="icon-password"
                id="icon-password"
                (click)="togglePassword()"
              ></span>
            </div>
            <label for="password">Password</label>
            <div class="forgot-link">
              <a routerLink="/forgot-password">Forgot Password?</a>
            </div>
          </div>
          <button
            type="button"
            class="btn d-flex align-items-center justify-content-center"
            (click)="loading ? false : loginForm()"
          >
            <span>Login</span>
            <!-- <span *ngIf="loading" class="spinner6"><div></div></span> -->
          </button>
          <div class="other-link">
            <p>Don't have an account? <a routerLink="/signup">Sign Up</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
