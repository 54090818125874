<div class="menu-sidebar">
  <a href="javascript:" class="menu-close"></a>
  <div class="box-menu">
    <div
      *ngIf="user"
      class="profile-box"
      (click)="closeMenu()"
      routerLink="/edit-profile"
    >
      <div class="img">
        <img
          [src]="user.profile_pic ? user.profile_pic : ' assets/img/user.jpg'"
          (error)="updateUrl($event)"
          alt=""
          width="70"
        />
      </div>
      <div class="info">
        <span class="welcome">Welcome</span>
        <span class="name">{{ user.full_name }}</span>
        <span class="rewards"
          >Earned Points - <strong>{{ coins }}</strong></span
        >
      </div>
    </div>
    <ul>
      <li>
        <a (click)="closeMenu()" routerLink="/home">
          <span class="icon-home"></span>
          <span class="main-link">Home</span>
          <span class="sub-text">Look at your feed</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/leaderboard">
          <span class="icon-leaderboard"></span>
          <span class="main-link">Leaderboard</span>
          <span class="sub-text">Who has the most points</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/game">
          <span class="icon-activity"></span>
          <span class="main-link">Game Activity</span>
          <span class="sub-text">See your daily activities</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/resources">
          <span class="icon-resources"></span>
          <span class="main-link">Resources</span>
          <span class="sub-text">Explore career resources</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/disruptors">
          <span class="icon-disruptors"></span>
          <span class="main-link">Disruptors</span>
          <span class="sub-text">Get to know latest industry disruptors</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/game-rules/menu">
          <span class="icon-rules"></span>
          <span class="main-link">Game Rules</span>
          <span class="sub-text">See all rules here</span>
        </a>
      </li>
      <li>
        <a (click)="closeMenu()" routerLink="/job-search-form">
          <span class="icon-search"></span>
          <span class="main-link">Search a Job</span>
          <span class="sub-text">Search jobs in your area</span>
        </a>
      </li>
    </ul>

    <a class="btn btn-logout">Logout</a>

    <div class="menu-footer">
      <a (click)="closeMenu()" routerLink="/need-help">Help?</a>
      <a (click)="closeMenu()" routerLink="/faq">FAQ</a>
    </div>
  </div>
</div>

<div class="popup-logout">
  <div class="popup-box">
    <p>Are you sure you want to logout?</p>
    <div class="action">
      <button class="btn btn-yes" type="button" (click)="logout()">Yes</button>
      <button class="btn btn-no" type="button">No</button>
    </div>
  </div>
</div>

<div
  *ngIf="!router.url.includes('game/') && router.url != '/game'"
  class="header small menu-header"
>
  <div class="nav-button" (click)="openMenu()">Menu</div>
  <div class="page-heading">{{ title }}</div>
  <div
    class="notification"
    [ngClass]="{ 'notif-badge': notifsCount }"
    [attr.data-count]="notifsCount"
    routerLink="/notifications"
  >
    Notification
  </div>
</div>

<!-- <div class="scroll-down-btn1"></div> -->

<router-outlet (activate)="onActivate($event)"></router-outlet>
