import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-game-rules',
  templateUrl: './game-rules.component.html',
  styleUrls: ['./game-rules.component.css'],
})
export class GameRulesComponent implements OnInit {
  day: any = 'menu';
  check: any;
  constructor(
    private menuService: MenuService,
    private _route: ActivatedRoute,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.menuService.updateTitle('GAME RULES');
  }

  ngOnInit(): void {
    this.day = this._route.snapshot.params.day;
    this.http
      .get('https://admin.careerforward.ca/api/get_game_role', {
        headers: this.reqHeaders(),
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.check = res.data[0].game_role ? true : false;
        }
      });
  }

  updateCheck() {
    setTimeout(() => {
      console.log(this.check);
      this.http
        .post(
          'https://admin.careerforward.ca/api/game_role_update',
          { game_role: this.check },
          {
            headers: this.reqHeaders(),
          }
        )
        .subscribe((res: any) => {
          console.log(res);
          if (res.status) {
            // this.check = res.data[0].game_role;
          }
        });
    }, 0);
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
