import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css'],
})
export class ResourcesComponent implements OnInit {
  constructor(
    private router: Router,
    private http: HttpClient,
    private menu: MenuService
  ) {
    this.menu.updateTitle('RESOURCES');
  }

  ngOnInit() {}
}
