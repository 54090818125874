<div class="content-wrapper">
  <div class="leaderboard-page" *ngIf="!loading">
    <div class="profile-block">
      <img
        [src]="
          myDetails.profile_pic ? myDetails.profile_pic : ' assets/img/user.jpg'
        "
        (error)="updateUrl($event)"
        alt=""
      />
      <span class="name">{{ myDetails.name }}</span>
      <span class="sub-text"
        >Score - <strong>{{ myDetails.p }}</strong></span
      >
      <span class="sub-text"
        >Ranking -
        <strong>{{ myDetails.rank ? myDetails.rank : "NA" }}</strong></span
      >
    </div>

    <p class="blue-text">
      The more activities you explore,<br />
      more points you’ll earn.
    </p>

    <div class="table-leaderboard">
      <div class="table-header">
        <div class="name">Name</div>
        <div class="points">Points</div>
        <div class="rank">Rank</div>
      </div>
      <ul class="table-body">
        <li
          *ngFor="let user of users; let i = index"
          [attr.id]="'user-' + user.id"
          [ngClass]="{ 'my-rank': user.id == myDetails.id }"
        >
          <div class="name-box">
            <span><img
              width="40"
              height="40"
              [src]="
                user.profile_pic ? user.profile_pic : ' assets/img/user.jpg'
              "
              (error)="updateUrl($event)"
              alt=""
            /></span>
            <span class="name">{{ user.first_name }}</span>
          </div>
          <div class="points-box">{{ user.p }}</div>
          <div class="rank-box">{{ user.rank }}</div>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
