import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, NgZone, ApplicationRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import { takeUntil, filter, map } from 'rxjs/operators';
import { TrackService } from 'src/app/services/track.service';
import { Subject } from 'rxjs';
import $ from 'jquery';
@Component({
  selector: 'app-questions-success',
  templateUrl: './questions-success.component.html',
  styleUrls: ['./questions-success.component.css'],
})
export class QuestionsSuccessComponent implements OnInit {
  activities: any;
  loading: boolean = false;
  day: any;
  selectedActivity: any;
  private subscriptionDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private cookie: CookieService,
    private http: HttpClient,
    private location: PlatformLocation,
    private trackService: TrackService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
    this._route.params.pipe(takeUntil(this.subscriptionDestroyed$)).subscribe((data) => {
      this.initComponent();
    })
    $(window).bind("pageshow", function(event) {
      if (event.originalEvent.persisted) {
          window.location.reload() 
      }
  });
  }

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent() {
    console.log('Init Component');
    this.day = this._route.snapshot.params.day;
    // this.loadData();
    this.timeTrack()
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    if (!this.loading) {
      this.loading = true;
      this.http
        .post(
          'https://admin.careerforward.ca/api/questions',
          { day: this.day },
          {
            headers: this.reqHeaders(),
          }
        )
        .subscribe((res: any) => {
          if (res.status) {
            if (res.type == 'activity') {
              let all_complete: boolean = true;
              res.data.forEach((e) => {
                if (!e.read_status) {
                  all_complete = false;
                }
              });
              if (all_complete) {
                if (res.redirection) {
                  this.router.navigate(['/activities-success', this.day]);
                }
              }
              this.activities = res.data;
              this.loading = false;
            }
          }
        });
    }
  }

  openResource(item) {
    this.selectedActivity = item;
    if (item.type == 'Video' || item.type == 'Document') {
      this.markAsRead(null);
      this.router.navigate(['/activity', item.id]);
    } else {
      this.openPopup();
    }
  }

  openPopup() {
    document.querySelector('.popup').classList.add('active');
  }

  closePopup() {
    document.querySelector('.popup').classList.remove('active');
  }

  redirect() {
    // this.trackService.isRunning = true;
    // this.trackService.timer = setTimeout(() => {
    this.markAsRead('website');
    //     this.trackService.isRunning = false;
    // }, 30000);
  }

  markAsRead(type) {
    this.http
      .post(
        'https://admin.careerforward.ca/api/save_activity_response',
        {
          question_id: this.selectedActivity.question_id,
          id: this.selectedActivity.id,
          activity_id: this.selectedActivity.activity_id,
          response: 'yes',
          day: this.day,
        },
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        if (res.status) {
          if (type == 'website') {
            this.closePopup();
            window.location.href = this.selectedActivity.detail;
          }
          this.menu.updateCoins(res.points);
        }
      });
  }

  timeTrack() {

    let params = new HttpParams();
    params = params.append('key', '');
    params = params.append('value', 'a');
    params = params.append('disabled', 'true');
    const activites = this.http
      .post(
        'https://admin.careerforward.ca/api/saveActivity_read_response',
        { day: this.day },
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        activites.unsubscribe()
        console.log(res)
        console.log(this.day)
        if (res.status) {
          // debugger;
          let all_complete: boolean = false;
              for(const e of res.data) {
                if (!e.read_status) {
                  all_complete = true;
                }
              }
              if (!all_complete) {
                this.router.navigate(['/activities-success', this.day]);
              }
              this.activities = res.data;
              this.loading = false;
        }
      })
  }

  // scrollBtn() {
  //   let div = document.querySelector('.activity-success-block');
  //   if (div) {
  //     if (div.scrollHeight > screen.height && window.pageYOffset < 20) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  ngAfterViewInit() {
    document.addEventListener('scroll', () => {
      // console.log(window.pageYOffset);
    });
  }
}
