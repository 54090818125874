import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FirebaseMessagingService } from './services/firebase-messaging.service';
import { MenuService } from './services/menu.service';

// import * as LogRocket from 'logrocket';

// LogRocket.init('hzth23/careerforward');
// LogRocket.identify('111', {
//   name: 'best song',
//   email: 'best3dsong@gmail.com',

//   // Add your own custom user variables here, ie:
//   subscriptionType: 'pro'
// });
// LogRocket.captureMessage('Something is wrong!', {
//   tags: {
//     // additional data to be grouped as "tags"
//     subscription: 'Pro',
//   },
//   extra: {
//     // additional arbitrary data associated with the event
//     pageName: 'ProfileView',
//   },
// });
// LogRocket.track('Registered');
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css'],
// })

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'CareerForward';
  message: any;
  constructor(
    private messagingService: FirebaseMessagingService,
    private http: HttpClient,
    private cookie: CookieService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    if (this.cookie.check('user') && this.cookie.check('token')) {
      this.http
        .post(
          'https://admin.careerforward.ca/api/total_points',
          {},
          {
            headers: this.reqHeaders(),
          }
        )
        .subscribe((res: any) => {
          console.log(res);
          this.menuService.updateCoins(res.data[0].points);
        });
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
      // this.message = this.messagingService.currentMessage;
    }
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
