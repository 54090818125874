import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-career-development',
  templateUrl: './career-development.component.html',
  styleUrls: ['./career-development.component.css'],
})
export class CareerDevelopmentComponent implements OnInit {
  loading: boolean = false;
  categories: any = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('CAREER DEVELOPMENT');
  }

  ngOnInit() {
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    this.loading = true;
    this.http
      .post(
        'https://admin.careerforward.ca/api/resource_category',
        { resource_id: 1 },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.categories = res.data;
        }
        this.loading = false;
      });
  }
}
