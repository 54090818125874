import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-game-confirmation',
  templateUrl: './game-confirmation.component.html',
  styleUrls: ['./game-confirmation.component.css'],
})
export class GameConfirmationComponent implements OnInit {
  selectedDay: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private _route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
  }

  ngOnInit() {
    this.selectedDay = this._route.snapshot.params.day;
  }
}
