import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-indeed-job-search',
  templateUrl: './indeed-job-search.component.html',
  styleUrls: ['./indeed-job-search.component.css'],
})
export class IndeedJobSearchComponent implements OnInit {
  jobTitle: string;
  jobLocation: any;
  publisherID: any;

  autoCompleteOptions = {
    types: ['(regions)'],
    componentRestrictions: { country: 'CA' },
  };

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  constructor(
    private http: HttpClient,
    private router: Router,
    private menu: MenuService
  ) {
    this.menu.updateTitle('JOB SEARCH');
  }

  ngOnInit() {
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }

  handleAddressChange(address: any) {
    console.log(address);
    let index;
    address.address_components.forEach((comp, i) => {
      if (comp.types.includes('country')) {
        index = i - 1;
      }
    });
    this.jobLocation = '';
    setTimeout(() => {
      this.jobLocation =
        address.name + ', ' + address.address_components[index].short_name;
    }, 0);
  }

  searchJobs() {
    let job, location;
    job = (document.getElementById('jobTitle') as HTMLFormElement).value;
    location = (document.getElementById('location') as HTMLFormElement).value;
    console.log(job, location);
    if (!job && !location) {
      Swal.fire({
        title: 'Please enter a Job Title and Location',
        allowOutsideClick: false,
      });
    } else {
      this.router.navigate(['/job-search-results'], {
        queryParams: { q: job, l: location, start: 0 },
      });
    }
  }
}
