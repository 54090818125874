<div class="content-wrapper" *ngIf="id == '2'">
  <div class="activity-content">
    <img src="assets/img/activity-2.jpg" alt="" />
    <div class="content">
      <h3>How to write your resume?</h3>
      <ol>
        <li>Pick the Right Resume Format & Layout</li>
        <li>Mention Your Personal Details & Contact Information</li>
        <li>Use a Resume Summary or Objective</li>
        <li>List Your Work Experience & Achievements</li>
        <li>Mention Your Top Soft & Hard Skills</li>
        <li>
          (Optional) Include Additional Resume Sections - Languages, Hobbies,
          etc.
        </li>
        <li>Tailor Your Information For the Job Ad</li>
        <li>Craft a Convincing Cover Letter</li>
        <li>Proofread Your Resume and Cover Letter</li>
      </ol>
    </div>
  </div>
</div>

<div class="content-wrapper" *ngIf="id == '3'">
  <div class="header small menu-header">
    <div class="nav-button" (click)="openMenu()">Menu</div>
    <div class="page-heading"></div>
    <div class="notification" (click)="notification()">Notification</div>
  </div>

  <div class="activity-3">
    <h3>Read about the types of workers.</h3>
    <p>
      A strong organization mirrors a well-designed puzzle. In order for
      everything to look good and function properly, all of the pieces must
      differ. If the pieces are all the same, they won’t fit together, and the
      puzzle can’t be built. Any stable organization will have a diverse group
      of individuals, with the same overarching goal. Just like a puzzle, each
      piece is unique, but when assembled, a unified image will be formed.
    </p>
    <h3>The top 10 work skills of tomorrow</h3>
    <ul>
      <li>Analytical thinking and innovation</li>
      <li>Complex problem-solving</li>
      <li>Critical thinking and analysis</li>
      <li>Active learning and learning strategies</li>
      <li>Creativity, originality and initiative</li>
      <li>Attention to detail, trustworthiness</li>
      <li>Emotional intelligence</li>
      <li>Reasoning, problem-solving and ideation</li>
      <li>Leadership and social influence</li>
      <li>Coordination and time management</li>
    </ul>
    <div class="actions">
      <a href="#" class="download" download>Download Goals.pdf</a>
      <a href="#" class="share"><span></span>Share</a>
    </div>
  </div>
</div>
