<body>
  <div class="continue-game-page">
    <div class="game-box">
      <span class="icon-circle-warning"></span>
      <p>
        Due to a disrupter, you are required to select a new occupation to look
        for. Please select from the drop-down box a new occupation to continue
        with the game.
      </p>

      <div class="game-dropdown">
        <select
          name="games-list"
          id="game"
          class="game-list"
          data-placeholder="Select your industry/occupation"
        >
          <option selected disabled value="">
            Select your industry/occupation
          </option>
          <option *ngFor="let industry of industries" [value]="industry.id">
            {{ industry.industry_name }}
          </option>
        </select>
      </div>
    </div>
    <a (click)="saveIndustry()" class="btn">Continue Game</a>
  </div>
</body>
