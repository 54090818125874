import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent implements OnInit {
  user: any;
  title: any;
  coins: any;
  notifsCount: any = 0;
  constructor(
    private menuService: MenuService,
    private cookie: CookieService,
    public router: Router,
    private authService: AuthService,
    private messagingService: FirebaseMessagingService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.menuService.coins.subscribe((coinsCount) => {
      if (coinsCount) {
        this.coins = coinsCount;
      } else {
        this.coins = 0;
      }
    });

    this.menuService.clearAllNotifs.subscribe((res) => {
      if (res) {
        this.notifsCount = 0;
      }
    });

    this.authService.user.subscribe((_user) => {
      if (_user) {
        console.log(_user);
        this.user = _user;
      } else {
        this.router.navigate(['/splash-screen']);
      }
    });

    this.menuService.title.subscribe((res) => {
      this.title = res;
    });

    this.menuService.menuOpen.subscribe((open) => {
      if (open) {
        document.querySelector('.menu-sidebar').classList.add('active');
      }
    });

    this.messagingService.currentMessage.subscribe((msg) => {
      console.log(msg);
      if (msg) {
        if (msg.data.count)
          this.notifsCount =
            Number(msg.data.count) > 9 ? '9+' : Number(msg.data.count);
        if (document.querySelector('.notification')) {
          document.querySelector('.notification').classList.add('isAnimating');
          setTimeout(() => {
            document
              .querySelector('.notification')
              .classList.remove('isAnimating');
          }, 2000);
        }
      }
    });

    this.http
      .post(
        'https://admin.careerforward.ca/api/notification_count',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.notifsCount = Number(res.count) > 9 ? '9+' : Number(res.count);
          if (document.querySelector('.notification') && Number(res.count)) {
            document
              .querySelector('.notification')
              .classList.add('isAnimating');
            setTimeout(() => {
              document
                .querySelector('.notification')
                .classList.remove('isAnimating');
            }, 2000);
          }
        }
      });
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  updateUrl(e) {
    e.target.src = 'assets/img/user.jpg';
  }

  openMenu() {
    document.querySelector('.menu-sidebar').classList.add('active');
  }

  closeMenu() {
    document.querySelector('.menu-sidebar').classList.remove('active');
  }

  logout() {
    this.authService.logout().subscribe((res: any) => {
      if (res.status) {
        this.cookie.delete('token');
        this.cookie.delete('user');
        this.closeMenu();
        this.menuService.updateMenuOpenStatus(false);
        document.querySelector('.popup-logout').classList.remove('active');
        this.router.navigate(['/login']);
      }
    });
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  ngAfterViewInit() {
    document.querySelector('.menu-close').addEventListener('click', (e) => {
      e.preventDefault();
      this.closeMenu();
    });

    document
      .querySelector('.btn-logout')
      .addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector('.popup-logout').classList.add('active');
      });

    document.querySelector('.btn-no').addEventListener('click', function (e) {
      e.preventDefault();
      document.querySelector('.popup-logout').classList.remove('active');
    });
  }
}
