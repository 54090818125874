<div
  class="flex-grow-1 my-3 d-flex align-items-center justify-content-center"
  id="board"
>
  <div
    class="content-wraper d-flex align-items-center justify-content-center"
    *ngIf="questions.length == 0 || loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
  <div
    class="question-card d-flex flex-column"
    *ngFor="let q of questions; let i = index"
    [attr.id]="'q' + q.id"
  >
    <!-- prettier-ignore-attribute -->
    <div
      style="
              background: url('https://picsum.photos/320/200/?random={{200*i}}') center; 
              width: 100%; 
              flex-grow: 1;
              background-size: cover;"
    ></div>
    <p class="p-3 text-center" style="font-size: 2.3vh">
      <b>{{ q.question }}</b>
    </p>
  </div>
</div>

<div class="m-3">
  <p style="font-size: 2.2vh" class="text-center">
    Swipe Left for <strong>“NO”</strong> or Right for <strong>“YES”</strong>
  </p>
  <div
    class="bottom-arrow-panel d-flex align-items-center justify-content-center mt-4"
  >
    <div class="flex-fill px-2 text-left">
      <i class="fa fa-long-arrow-left fa-3x" aria-hidden="true"></i>
    </div>
    <div class="flex-fill px-2 text-right">
      <i
        class="fa fa-times-circle fa-5x"
        style="color: #f32900"
        aria-hidden="true"
        (click)="swipeLeft($event)"
      ></i>
    </div>
    <div class="flex-fill px-2 text-left">
      <i
        class="fa fa-check-circle fa-5x"
        style="color: #8bd200"
        aria-hidden="true"
        (click)="swipeRight($event)"
      ></i>
    </div>
    <div class="flex-fill px-2 text-right">
      <i class="fa fa-long-arrow-right fa-3x" aria-hidden="true"></i>
    </div>
  </div>
</div>

<div class="popup-disruptor">
  <div class="popup-box">
    <div class="d-flex flex-column" *ngIf="disruptor.length > 0">
      <img
        class="mb-3"
        src="assets/img/disruptor-question.svg"
        alt="img"
        width="100%"
      />
      <p class="p-3 text-center" style="font-size: 2.3vh">
        <b>{{ disruptor[0].question }}</b>
      </p>
      <div class="action">
        <button
          class="btn btn-yes"
          type="button"
          (click)="saveAnswer(disruptor[0].id, 'YES', 'disruptor')"
        >
          Yes
        </button>
        <button
          class="btn btn-no"
          type="button"
          (click)="saveAnswer(disruptor[0].id, 'NO', 'disruptor')"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
