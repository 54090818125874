<div class="content-wrapper">
  <div
    class="resources-page sub-category-page"
    style="height: 100%"
    *ngIf="!loading"
  >
    <app-video-resource
      *ngIf="activity.type == 'Video'"
      [resource]="activity"
      [type]="'activity'"
    ></app-video-resource>

    <div
      *ngIf="activity.type == 'Document' && !openingDocument"
      style="height: 100%"
    >
      <iframe
        *ngIf="docLink"
        [src]="docLink"
        style="width: 100vw"
        height="100%"
      ></iframe>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex flex-column align-items-center justify-content-center"
    style="background-color: #fff"
    *ngIf="openingDocument"
  >
    <p class="opening">
      Opening Document<span class="ml-3">.</span><span>.</span><span>.</span>
    </p>
    <p class="text-center mx-3">
      In order to come back to the activities, you need to tap on the back
      button of the device.
    </p>
  </div>
</div>
