import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HomeComponent } from './pages/home/home.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { IntroComponent } from './pages/intro/intro.component';
import { GameHomeComponent } from './pages/game-home/game-home.component';
import { GameConfirmationComponent } from './pages/game-confirmation/game-confirmation.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CareerDevelopmentComponent } from './pages/career-development/career-development.component';
import { CareerDevelopmentSubcategoryComponent } from './pages/career-development-subcategory/career-development-subcategory.component';
import { JobSearchComponent } from './pages/job-search/job-search.component';
import { JobSearchSubcategoryComponent } from './pages/job-search-subcategory/job-search-subcategory.component';
import { NeedHelpComponent } from './pages/need-help/need-help.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { LmiFormComponent } from './pages/lmi-form/lmi-form.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { SuccessMessageComponent } from './pages/success-message/success-message.component';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionsComponent } from './pages/questions/questions.component';
import { QuestionsSuccessComponent } from './pages/questions-success/questions-success.component';
import { ActivitiesSuccessComponent } from './pages/activities-success/activities-success.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { PreviousDayActivityComponent } from './pages/previous-day-activity/previous-day-activity.component';
import { SwiperModule } from 'swiper/angular';
import { GameRulesComponent } from './pages/game-rules/game-rules.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { LmiResultsComponent } from './pages/lmi-results/lmi-results.component';
import { IndeedJobSearchComponent } from './pages/indeed-job-search/indeed-job-search.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { DisruptorsComponent } from './pages/disruptors/disruptors.component';
import { ContinueDisruptorComponent } from './pages/continue-disruptor/continue-disruptor.component';
import { VideoResourceComponent } from './pages/video-resource/video-resource.component';
import { ResourceItemComponent } from './pages/resource-item/resource-item.component';
import { ActivityItemComponent } from './pages/activity-item/activity-item.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { LineTruncationLibModule } from 'ngx-line-truncation';
import { DisruptorContentComponent } from './pages/disruptor-content/disruptor-content.component';
import { JobSearchResultsComponent } from './pages/job-search-results/job-search-results.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    ForgotPasswordComponent,
    IntroComponent,
    GameHomeComponent,
    GameConfirmationComponent,
    ResetPasswordComponent,
    AnnouncementComponent,
    FaqComponent,
    CareerDevelopmentComponent,
    CareerDevelopmentSubcategoryComponent,
    JobSearchComponent,
    JobSearchSubcategoryComponent,
    NeedHelpComponent,
    ResourcesComponent,
    LmiFormComponent,
    NotificationsComponent,
    SuccessMessageComponent,
    SideMenuComponent,
    EditProfileComponent,
    QuestionsComponent,
    QuestionsSuccessComponent,
    ActivitiesSuccessComponent,
    ActivityComponent,
    PreviousDayActivityComponent,
    GameRulesComponent,
    LeaderboardComponent,
    LmiResultsComponent,
    IndeedJobSearchComponent,
    CalendarComponent,
    DisruptorsComponent,
    ContinueDisruptorComponent,
    VideoResourceComponent,
    ResourceItemComponent,
    ActivityItemComponent,
    DisruptorContentComponent,
    JobSearchResultsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    LineTruncationLibModule,
    MatExpansionModule,
    MatPaginatorModule,
    GooglePlaceModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    })
  ],
  providers: [
    CookieService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
