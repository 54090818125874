import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackService {
  isRunning: boolean = false;
  timer: any;
  z:any = 10;
  constructor() { }
}
