import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from '../../services/menu.service';
import $ from 'jquery';

@Component({
  selector: 'app-disruptors',
  templateUrl: './disruptors.component.html',
  styleUrls: ['./disruptors.component.css'],
})
export class DisruptorsComponent implements OnInit {
  loading: boolean = false;
  step;
  disruptors: any;
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private _route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.menu.updateTitle('DISRUPTORS');
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/disruptors',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.disruptors = res.data;
          this.step = sessionStorage.getItem('d_expand')
            ? Number(sessionStorage.getItem('d_expand'))
            : 0;
        }
      });
  }

  setStep(index: number) {
    this.step = index;
    sessionStorage.setItem('d_expand', index.toString());
  }

  // changeArrow(i) {
  //   this.disruptors.forEach((d, j) => {
  //     if (j == i) d.active = !d.active;
  //     else d.active = false;
  //   });
  // }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
