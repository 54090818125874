import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css'],
})
export class AnnouncementComponent implements OnInit {
  id: any;
  announcement: any;
  loading: boolean = true;
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private _route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.menu.updateTitle('ANNOUNCEMENT');
  }

  ngOnInit() {
    this.id = this._route.snapshot.params.id;
    this.http
      .post(
        'https://admin.careerforward.ca/api/announcement_detail',
        { id: this.id },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        this.announcement = res.data;
        this.loading = false;
      });
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
