import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-disruptor-content',
  templateUrl: './disruptor-content.component.html',
  styleUrls: ['./disruptor-content.component.css'],
})
export class DisruptorContentComponent implements OnInit {
  id: any;
  disruptor: any;
  loading: boolean = true;

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private _route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
  }

  ngOnInit(): void {
    this.id = this._route.snapshot.params.id;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.http
      .post(
        'https://admin.careerforward.ca/api/get_disruptor_detail',
        { id: this.id },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.disruptor = res.data[0];
          this.loading = false;
        }
      });
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
