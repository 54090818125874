<div class="content-wrapper">
  <div class="resources-page sub-category-page" *ngIf="!loading">
    <!-- <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-leadership"></span>
        <span class="text">Leadership</span>
      </a>
    </div>
    <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-management"></span>
        <span class="text">Management</span>
      </a>
    </div>
    <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-creativity"></span>
        <span class="text">Creativity</span>
      </a>
    </div>
    <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-research"></span>
        <span class="text">Research</span>
      </a>
    </div>
    <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-lmi"></span>
        <span class="text">Information</span>
      </a>
    </div>
    <div class="block-resource">
      <a href="javascript:">
        <span class="icon-circle icon-circle-analysis"></span>
        <span class="text">Analysis</span>
      </a>
    </div> -->

    <ol>
      <li class="topic-item" *ngFor="let item of topics">
        <span (click)="openResource(item)">{{ item.topic }}</span>
      </li>
    </ol>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>

<div class="popup">
  <div class="popup-box">
    <p class="mb-5 text-center">
      You are being redirected to the third-party website. In order to come back
      to the application, you need to tap on the back button of the device.
    </p>
    <div class="action">
      <button class="btn btn-yes my-2" type="button" (click)="redirect()">
        Proceed To Website
      </button>
      <p class="text-center" style="font-size: 14px" (click)="closePopup()">
        Cancel
      </p>
      <!-- <button class="btn btn-no my-2" type="button">Cancel</button> -->
    </div>
  </div>
</div>
