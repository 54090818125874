import { Component, OnInit } from '@angular/core';
import SwiperCore, { Pagination } from 'swiper/core';
import { Swiper } from 'swiper';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
})
export class IntroComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}
