import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-resource',
  templateUrl: './video-resource.component.html',
  styleUrls: ['./video-resource.component.css'],
})
export class VideoResourceComponent implements OnInit {
  @Input() resource: any;
  @Input() type: any;
  video: any = {};
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.type == 'activity') {
      this.video.link = this.resource.detail;
      this.video.title = this.resource.activity_name;
    } else if (this.type == 'resource') {
      this.video.link = this.resource.link;
      this.video.title = this.resource.topic;
    } else if (this.type == 'disruptor') {
      this.video.link = this.resource.link;
      this.video.title = this.resource.title;
    }

    console.log(this.video);

    let link = this.video.link;
    if (link.includes('v=')) {
      link = link.split('v=')[1];
      link = link.split('&')[0];
    } else if (link.includes('youtu.be')) {
      link = link.split('/').pop();
      link = link.split('&')[0];
    }
    link = `https://www.youtube.com/embed/${link}`;
    this.video.embedLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    console.log('========>>>', this.video.embedLink);
  }
}
