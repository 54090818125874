<div class="content-wrapper">
  <div class="header small">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="" width="220" />
    </div>
  </div>
  <div
    class="intro-block swiper-container"
    style="padding-top: 50%; padding-bottom: 20px; height: 100%"
  >
    <div class="intro-slider swiper-wrapper">
      <div class="swiper-slide">
        <div class="media">
          <img src="../../assets/img/intro-1.png" alt="" />
        </div>
        <div class="content">
          <h2>WELCOME</h2>
          <p>
            Let CareerForward prepare you for your next great job! Over the next
            10 days, answer questions and complete activities that will equip
            you with skills in career exploration, job search and job
            maintenance. For every activity completed, you gain points. For
            additional information, check out the resources section. Let's get
            started!
          </p>
        </div>
        <div class="action">
          <a routerLink="/home" onclick="return false;" class="link">Skip</a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="media">
          <img src="../../assets/img/intro-1.png" alt="" />
        </div>
        <div class="content">
          <h2>PLAY GAME</h2>
          <p>
            You have 10 days to complete the game. Each day, answer 5 questions
            and complete 5 activities. Swipe left for "No" and right for "Yes."
            Activities may be reading an article or watching a video. Earn
            points every time you answer a question or complete an activity!
            Watch for "disruptor" questions that will force you to change
            course. Learn more about the disruptors impacting today's workforce,
            both locally and globally--from technology to pandemics to an aging
            population!
          </p>
        </div>
        <div class="action">
          <a routerLink="/home" onclick="return false;" class="link">Skip</a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="media">
          <img src="../../assets/img/intro-2.png" alt="" />
        </div>
        <div class="content">
          <h2>EXPLORE RESOURCES</h2>
          <p>
            Here, you will find up-to-date knowledge on many career-related and
            job search topics. Find videos and articles that will answer your
            top questions. These resources will be available to you at any time:
            before, during and after playing the game.
          </p>
        </div>
        <div class="action">
          <a routerLink="/home" onclick="return false;" class="link">Skip</a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="media">
          <img src="../../assets/img/intro-1.png" alt="" />
        </div>
        <div class="content">
          <h2>WHY CAREERFORWARD?</h2>
          <p>
            Careerforward is a job search app designed to help you prepare for
            your next job and manage your career. Play the game to learn more
            about job search and job readiness skills and how you can best
            navigate your job search around disruptors. Access the resources to
            gain additional knowledge on today's labour market. Your one-stop
            shop for career and job search.
          </p>
        </div>
        <div class="action">
          <a routerLink="/home" onclick="return false;" class="link"
            >GET STARTED</a
          >
        </div>
      </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination">SKIP</div>
  </div>
</div>
