<div class="content-wrapper">
  <div class="disruptor-page">
    <h3>Disruptors</h3>

    <p class="card border-0 my-2 p-2">
      Below you will find a list of disruptors that will change the type of work
      we do in the future. Watch the videos below to learn more about how the
      labour market will be impacted.
    </p>

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel
        *ngFor="let d of disruptors; let i = index"
        [expanded]="step === i"
        (opened)="setStep(i)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{ d.title }} </mat-panel-title>
          <!-- <mat-panel-description>
            Type your name and age
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div [innerHTML]="d.description"></div>
        <div
          *ngIf="d.type == 'Video'"
          class="video-btn my-3"
          [routerLink]="['/disruptor-content', d.id]"
        >
          <i class="fa fa-play-circle fa-3x" aria-hidden="true"></i> Watch Video
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- <div class="accordion" id="accordionExample">
      <div class="card" *ngFor="let d of disruptors; let i = index">
        <div class="card-header" id="headingOne">
          <p
            class="d-flex align-items-center"
            data-toggle="collapse"
            [attr.data-target]="'#collapse' + d.id"
            aria-expanded="true"
            (click)="changeArrow(i)"
          >
            <i
              class="fa mr-2"
              [ngClass]="{
                'fa-chevron-right': d.active,
                'fa-chevron-down': !d.active
              }"
              aria-hidden="true"
            ></i>
            <strong>{{ d.title }}</strong>
          </p>
        </div>

        <div
          [attr.id]="'collapse' + d.id"
          class="collapse"
          [ngClass]="{ collapse: true, show: i == 0 }"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            <div [innerHTML]="d.description"></div>
            <div
              *ngIf="d.type == 'Video'"
              class="video-btn my-3"
              [routerLink]="['/disruptor-content', d.id]"
            >
              <i class="fa fa-play-circle fa-3x" aria-hidden="true"></i> Watch
              Video
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
