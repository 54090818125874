<div class="content-wrapper">
  <div class="container">
    <div class="form-section indeed">
      <div class="inner-wrap">
        <form id="jobForm">
          <div class="field">
            <div class="field-box">
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"
                [(ngModel)]="jobTitle"
                class="formControl"
              />
              <span class="icon-user"></span>
            </div>
            <label for="jobTitle">Job title, Keywords</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="text"
                name="location"
                id="location"
                ngx-google-places-autocomplete
                [options]="autoCompleteOptions"
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)"
                [(ngModel)]="jobLocation"
                placeholder=""
                class="formControl"
              />
              <span class="icon-location"></span>
            </div>
            <label for="location">Location</label>
          </div>

          <button type="submit" class="btn" (click)="searchJobs()">
            Search Jobs
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
