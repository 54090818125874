import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit() {
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });

    const togglePassword = document.querySelector('#icon-password');
    const password = document.querySelector('#password');
    togglePassword.addEventListener('click', function (e) {
      const type =
        password.getAttribute('type') === 'password' ? 'text' : 'password';
      password.setAttribute('type', type);
      this.classList.toggle('active');
    });
    const toggleconfirmPassword = document.querySelector(
      '#icon-confirm-password'
    );
    const confirmPassword = document.querySelector('#confirm-password');
    toggleconfirmPassword.addEventListener('click', function (e) {
      const confirmType =
        confirmPassword.getAttribute('type') === 'password'
          ? 'text'
          : 'password';
      confirmPassword.setAttribute('type', confirmType);
      this.classList.toggle('active');
    });

    const token = this.route.snapshot.paramMap.get('token');
    console.log('token in params==>', token);
  }

  resetPasswordForm() {
    const token = this.route.snapshot.paramMap.get('token');
    console.log('token in params==>', token);
    var password = (<HTMLInputElement>document.getElementById('password'))
      .value;
    var confirm_password = (<HTMLInputElement>(
      document.getElementById('confirm-password')
    )).value;

    if (!password) {
      Swal.fire({
        title: 'Password is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!confirm_password) {
      Swal.fire({
        title: 'Confirm password is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (password != confirm_password) {
      Swal.fire({
        title: "Passwors doesn't match.",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else {
      this.http
        .post('https://admin.careerforward.ca/api/reset_password', {
          user_token: token,
          password: password,
          password_confirmation: confirm_password,
        })
        .subscribe((response: any) => {
          console.log('response ===>', response);

          if (response.status == true) {
            Swal.fire({
              title: ' Your password has been changed successfully!',
              allowOutsideClick: false,
            }).then((result) => {
              this.router.navigate(['/login']);
            });
          } else {
            Swal.fire({
              title: `${response.message}`,
              allowOutsideClick: false,
            }).then((result) => {
              return false;
            });
          }
        });
    }
  }
}
