<div class="content-wrapper bg-gradient">
  <div class="header">
    <div class="nav-button" (click)="openMenu()">Menu</div>
    <div class="page-heading">
      <span *ngIf="currentDay">Day {{ this.selectedDay }}</span>
    </div>
    <div class="coins" *ngIf="coins">{{ coins }}</div>
  </div>

  <div
    class="container content-wrapper d-flex flex-column"
    style="overflow: hidden"
  >
    <div class="calendar-week swiper-container w-100">
      <div class="swiper-wrapper">
        <div
          class="box-day"
          [ngStyle]="{
            opacity: item.gameDay > currentDay || !item.gameDay ? '0.5' : '1'
          }"
          class="swiper-slide"
          *ngFor="let item of dates; let i = index"
          (click)="loadGameDataForDay(item.gameDay)"
        >
          <div class="day text-center">{{ item.day }}</div>
          <div class="text-center d-flex justify-content-center">
            <div
              class="date"
              [ngClass]="{ today: item.gameDay == selectedDay }"
            >
              {{ item.date }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-questions
      *ngIf="screen == 'question' && !loading"
      [questions]="data"
      [day]="selectedDay"
    ></app-questions>
    <app-previous-day-activity
      *ngIf="screen == 'activity' && !loading"
      [activities]="data"
      [day]="selectedDay"
      style="overflow-y: scroll "

    ></app-previous-day-activity>
  </div>
</div>

<div
  class="content-wrapper d-flex align-items-center justify-content-center"
  *ngIf="loading"
>
  <div class="spinner">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</div>

<div class="popup-prev-game">
  <div class="popup-box">
    <p>
      You have not answered Day {{ remaining_day }} questions. Would you like to
      go back and complete them now?
    </p>
    <div class="action">
      <button class="btn btn-yes" type="button" (click)="continuePrevGame()">
        Yes
      </button>
      <button class="btn btn-no" type="button" (click)="closePopup()">
        No
      </button>
    </div>
  </div>
</div>
