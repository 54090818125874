import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-job-search-results',
  templateUrl: './job-search-results.component.html',
  styleUrls: ['./job-search-results.component.css'],
})
export class JobSearchResultsComponent implements OnInit {
  searchURL: string = 'https://api.indeed.com/ads/apisearch?';
  queryParams: any;
  start: number = 0;
  loading: boolean = true;
  results: any = [];
  limit: number = 10;
  pages: any;
  currentPage: number = 1;
  constructor(
    private _route: ActivatedRoute,
    private http: HttpClient,
    private cookie: CookieService,
    private menu: MenuService,
    private router: Router
  ) {
    this.menu.updateTitle('RESULTS');
  }

  ngOnInit(): void {
    this.loading = true;

    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this._route.queryParams.subscribe((qparams) => {
          this.loading = true;
          this.start = Number(qparams.start);

          this.queryParams = {
            ...this._route.snapshot.queryParams,
            userip: res.ip,
            useragent: window.navigator.userAgent,
            limit: this.limit,
          };

          console.log(this.queryParams);
          this.loadData();
        });
      });
  }

  loadData() {
    this.http
      .post('https://admin.careerforward.ca/api/indeed', this.queryParams, {
        headers: this.reqHeaders(),
      })
      .subscribe((res: any) => {
        console.log(res);
        this.results = res.results;
        this.pages =
          Math.floor(res.totalResults / this.limit) +
          (res.totalResults % this.limit ? 1 : 0);
        this.currentPage = Math.floor(this.start / this.limit) + 1;

        this.loading = false;
      });
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.router.navigate(['/job-search-results'], {
        queryParams: {
          ...this._route.snapshot.queryParams,
          start: this.start - this.limit,
        },
      });
    }
  }

  nextPage() {
    if (this.currentPage < this.pages) {
      this.router.navigate(['/job-search-results'], {
        queryParams: {
          ...this._route.snapshot.queryParams,
          start: this.start + this.limit,
        },
      });
    }
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }
}
