import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-career-development-subcategory',
  templateUrl: './career-development-subcategory.component.html',
  styleUrls: ['./career-development-subcategory.component.css'],
})
export class CareerDevelopmentSubcategoryComponent implements OnInit {
  loading: boolean = true;
  topics: any = [];
  cat_id: any;
  selectedTopic: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _route: ActivatedRoute,
    private cookie: CookieService,
    private menu: MenuService
  ) {}

  ngOnInit() {
    this.cat_id = this._route.snapshot.params.id;
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    this.loading = true;
    this.http
      .post(
        'https://admin.careerforward.ca/api/resource_category_topics',
        { category_id: this.cat_id },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.topics = res.data;
          this.menu.updateTitle(res.data[0].category_name);
        }
        this.loading = false;
      });
  }

  openResource(item) {
    if (item.type == 'Video' || item.type == 'Document') {
      this.router.navigate(['/resource', item.id]);
    } else {
      this.openPopup(item);
    }
  }

  openPopup(topic) {
    this.selectedTopic = topic;
    document.querySelector('.popup').classList.add('active');
  }

  closePopup() {
    document.querySelector('.popup').classList.remove('active');
  }
  redirect() {
    this.closePopup();
    window.location.href = this.selectedTopic.link;
  }
}
