import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menuSubject = new BehaviorSubject<boolean>(false);
  menuOpen = this.menuSubject.asObservable();
  updateMenuOpenStatus(value) {
    this.menuSubject.next(value);
  }

  titleSubject = new BehaviorSubject<string>('');
  title = this.titleSubject.asObservable();
  updateTitle(value) {
    this.titleSubject.next(value);
  }

  coinSubject = new BehaviorSubject<string>(null);
  coins = this.coinSubject.asObservable();
  updateCoins(value) {
    this.coinSubject.next(value);
  }

  clearAllNotifsSubject = new BehaviorSubject<boolean>(false);
  clearAllNotifs = this.clearAllNotifsSubject.asObservable();
  updateClearAllNotifs(value) {
    this.clearAllNotifsSubject.next(value);
  }

  constructor() {}
}
