import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService,
    private toastr: ToastrService,
    private authService: AuthService,
    private menu: MenuService,
    private messagingService: FirebaseMessagingService
  ) {}

  ngOnInit() {
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }

  togglePassword() {
    const password = document.querySelector('#password');
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    password.classList.toggle('active');
  }

  loginForm() {
    var email = (<HTMLInputElement>document.getElementById('email')).value;
    var password = (<HTMLInputElement>document.getElementById('password'))
      .value;
    if (!email) {
      Swal.fire({
        title: 'Email is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!password) {
      Swal.fire({
        title: 'Password is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else {
      this.loading = true;
      var request = {
        email: email,
        password: password,
      };
      this.http
        .post('https://admin.careerforward.ca/api/login', request)
        .subscribe((response: any) => {
          console.log(response);
          if (response.status == true) {
            this.cookie.set('token', response.data.access_token, 1, '/');
            this.cookie.set(
              'user',
              JSON.stringify({
                ...response.data.user,
                full_name: `${response.data.user.first_name} ${response.data.user.last_name}`,
              }),
              1,
              '/'
            );
            this.menu.updateCoins(response.data.user.points);
            this.authService.updateUserDetails(
              this.cookie.check('user')
                ? JSON.parse(this.cookie.get('user'))
                : null
            );
            this.messagingService.requestPermission();
            this.messagingService.receiveMessage();

            if (response.data.user.first_time_login == 1) {
              this.router.navigate(['/intro']);
            } else {
              this.router.navigate(['/home']);
            }
            // this.toastr.success('Logged In Successfully', 'Success');
            this.loading = false;
          } else {
            if (response.message == 'Please Verify Email') {
              this.loading = false;
              Swal.fire({
                // title: 'Success',
                html: `<p>Account not Verified. ${response.message}.</p>`,
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: 'Close',
                showConfirmButton: true,
                confirmButtonText: 'Resend Verification Email ?',
                customClass: {
                  confirmButton: 'my-swal-button-class',
                  cancelButton: 'my-swal-button-class',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    text: 'Enter your registered email..',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off',
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    preConfirm: (emailID) => {
                      return fetch(
                        'https://admin.careerforward.ca/api/email/resend',
                        {
                          method: 'post',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ email: emailID }),
                        }
                      )
                        .then((response) => {
                          if (!response.ok) {
                            throw new Error(response.statusText);
                          }
                          return response.json();
                        })
                        .catch((error) => {
                          Swal.showValidationMessage(
                            `Request failed: ${error}`
                          );
                        });
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (result.value.status) {
                        Swal.fire({
                          icon: 'success',
                          title: 'Verification mail sent!',
                        });
                      }
                    }
                  });
                }
              });
            } else {
              this.toastr.error(`${response.message}`, 'Failed');
              this.loading = false;
            }
          }
        });
    }
  }
}
