import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  loading: boolean = false;
  constructor(private router: Router, private http: HttpClient) {}

  ngOnInit() {
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          $(this).next().addClass('active');
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        $(this).next().removeClass('active');
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }

  togglePassword() {
    const password = document.querySelector('#password');
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    password.classList.toggle('active');
  }

  toggleConfirmPassword() {
    const confirmPassword = document.querySelector('#confirm-password');
    const type =
      confirmPassword.getAttribute('type') === 'password' ? 'text' : 'password';
    confirmPassword.setAttribute('type', type);
    confirmPassword.classList.toggle('active');
  }

  signupForm() {
    var first_name = (<HTMLInputElement>document.getElementById('first-name'))
      .value;
    var last_name = (<HTMLInputElement>document.getElementById('last-name'))
      .value;
    var email = (<HTMLInputElement>document.getElementById('email')).value;
    var mobile_number = (<HTMLInputElement>document.getElementById('phone'))
      .value;
    var password = (<HTMLInputElement>document.getElementById('password'))
      .value;
    var confirm_password = (<HTMLInputElement>(
      document.getElementById('confirm-password')
    )).value;
    var termandcond = (<HTMLInputElement>document.getElementById('t-and-c'))
      .checked;

    if (!first_name) {
      Swal.fire({
        title: 'First name is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!last_name) {
      Swal.fire({
        title: 'Last name is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!email) {
      Swal.fire({
        title: 'Email is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!mobile_number) {
      Swal.fire({
        title: 'Mobile number is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!password) {
      Swal.fire({
        title: 'Password is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (!confirm_password) {
      Swal.fire({
        title: 'Confirm password is required.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (termandcond == false) {
      Swal.fire({
        title: 'Please check terms and condition.',
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else if (password != confirm_password) {
      Swal.fire({
        title: "Passwors doesn't match.",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    } else {
      this.loading = true;

      var request = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile_number: mobile_number,
        password: password,
        password_confirmation: confirm_password,
        terms_and_condition: '1',
      };

      this.http
        .post('https://admin.careerforward.ca/api/register', request)
        .subscribe((response: any) => {
          this.loading = false;
          if (response.status == true) {
            Swal.fire({
              title: 'Success',
              text:
                'A Verification Link has been sent to your email. Open the link to complete signup process',
              icon: 'success',
              allowOutsideClick: false,
            }).then((result) => {
              this.router.navigate(['/login']);
            });
          } else {
            var value = Object.values(response.data);
            Swal.fire({
              title: `${value[0]}`,
              allowOutsideClick: false,
            }).then((result) => {
              return false;
            });
          }
        });
    }
  }
}
