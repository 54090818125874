import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-continue-disruptor',
  templateUrl: './continue-disruptor.component.html',
  styleUrls: ['./continue-disruptor.component.css'],
})
export class ContinueDisruptorComponent implements OnInit {
  selectedIndustry: any;
  industries: any = [];
  day: any;
  constructor(
    private locationStrategy: LocationStrategy,
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private _route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
  }

  ngOnInit(): void {
    // this.preventBackButton();
    this.day = this._route.snapshot.params.day;
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  saveIndustry() {
    this.selectedIndustry = (<HTMLInputElement>(
      document.querySelector('#game')
    )).value;
    if (this.selectedIndustry) {
      console.log(this.selectedIndustry);
      this.http
        .post(
          'https://admin.careerforward.ca/api/update_industry',
          { industry_id: this.selectedIndustry, type: 'continue' },
          {
            headers: this.reqHeaders(),
          }
        )
        .subscribe((res: any) => {
          if (res.status) {
            console.log('Industry Changed!!');
            this.router.navigate(['/questions-success', this.day], {
              replaceUrl: true,
            });
          }
        });
    } else {
      Swal.fire({
        title: 'You need to select an Industry first !!',
        allowOutsideClick: false,
      });
    }
  }

  loadData() {
    this.http
      .get('https://admin.careerforward.ca/api/industries?type=continue', {
        headers: this.reqHeaders(),
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.industries = res.data;
        }
      });
  }

  preventBackButton() {
    history.pushState(
      null,
      null,
      location.href.replace('continue-disruptor', 'home')
    );
    this.locationStrategy.onPopState(() => {
      if (this.router.url == '/continue-disruptor')
        history.pushState(
          null,
          null,
          location.href.replace('continue-disruptor', 'home')
        );
    });
  }
}
