<div class="content-wrapper">
  <div
    class="resources-page"
    style="height: 100%; width: 100%"
    *ngIf="!loading"
  >
    <div class="jobs-grid">
      <div class="jobs-card mat-elevation-z2" *ngFor="let job of results">
        <a [href]="job.url"
          ><h4>{{ job.jobtitle }}</h4></a
        >

        <h5>{{ job.company }}</h5>
        <h5>{{ job.formattedLocation }}</h5>
        <div [innerHTML]="job.snippet"></div>
        <p>{{ job.formattedRelativeTime }}</p>
      </div>
    </div>

    <div class="paginator text-center" *ngIf="results.length > 0">
      <span
        ><i
          class="fa fa-angle-left"
          aria-hidden="true"
          [ngStyle]="{ color: currentPage == 1 ? '#555' : '#000' }"
          (click)="prevPage()"
        ></i
      ></span>
      <input
        type="number"
        style="text-align: center"
        readonly
        [(ngModel)]="currentPage"
      />
      <span> / </span>
      <span> {{ pages }} </span>
      <span
        ><i
          class="fa fa-angle-right"
          aria-hidden="true"
          [ngStyle]="{ color: currentPage == totalPages ? '#555' : '#000' }"
          (click)="nextPage()"
        ></i
      ></span>
    </div>

    <div
      class="no-results text-center"
      style="height: calc(100% - 70px)"
      *ngIf="results.length == 0"
    >
      <h5>No Results found..</h5>
      <p>Try searching using some different keywords !!</p>
    </div>

    <div class="container mb-3 text-right">
      <span id="indeed_at"
        ><a href="https://www.indeed.com/" rell="nofollow">jobs</a> by
        <a href="https://www.indeed.com/" rell="nofollow" title="Job Search"
          ><img
            src="https://www.indeed.com/p/jobsearch.gif"
            style="border: 0; vertical-align: middle"
            alt="Indeed job search" /></a
      ></span>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
