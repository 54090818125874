import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private menu: MenuService
  ) {
    this.menu.updateTitle('FAQ');
  }

  ngOnInit() {
    $(document).ready(function () {
      $('.acc-heading').click(function () {
        $('.acc-heading').not(this).removeClass('open').next().slideUp();
        $(this).toggleClass('open').next().slideToggle();
      });
    });
  }
}
