<div class="content-wrapper">
  <div class="header small">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="" width="270" />
    </div>
  </div>
  <div class="container">
    <div class="form-section">
      <div class="inner-wrap">
        <div class="icon-circle-block icon-reset-password"></div>
        <form enctype="multipart/form-data" onsubmit="javascript:return false;">
          <div class="field">
            <div class="field-box">
              <input
                type="password"
                name="password"
                id="password"
                class="formControl field-password"
              />
              <span class="icon-password" id="icon-password"></span>
            </div>
            <label for="password">New Password</label>
          </div>
          <div class="field mb-75">
            <div class="field-box">
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                class="formControl field-password"
              />
              <span class="icon-password" id="icon-confirm-password"></span>
            </div>
            <label for="confirm-password">Confirm New Password</label>
          </div>
          <button type="submit" class="btn" (click)="resetPasswordForm()">
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
