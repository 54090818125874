import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  currentMessageSubject = new BehaviorSubject(null);
  currentMessage = this.currentMessageSubject.asObservable();
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.http
          .post(
            'https://admin.careerforward.ca/api/token_update',
            {
              id: JSON.parse(this.cookie.get('user')).id,
              token: token,
            },
            { headers: this.reqHeaders() }
          )
          .subscribe((res) => {
            console.log(res);
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessageSubject.next(payload);
    });
  }
}
