<div class="content-wrapper">
  <div class="container">
    <div class="form-section">
      <div class="inner-wrap">
        <div class="icon-circle-block icon-success"></div>
        <div class="success-message-password">
          <p>Your password has been reset succeessfully</p>
        </div>
        <a href="#" onclick="return false;" class="btn">Continue</a>
      </div>
    </div>
  </div>
</div>
