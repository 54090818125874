import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-lmi-form',
  templateUrl: './lmi-form.component.html',
  styleUrls: ['./lmi-form.component.css'],
})
export class LmiFormComponent implements OnInit {
  nocArr = [];
  geoArr=[];
  placesArr = [];
  comingSoon = true;
  occupationControl;
  provinceControl;
  province;
  typingTimer: any
  noc: string
  nocFormData: any
  constructor(
    private http: HttpClient,
    private router: Router,
    private menu: MenuService,
    private cookie: CookieService  ) {
    this.menu.updateTitle('');
  }

  reqHeader() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  ngOnInit() {
    if(sessionStorage.getItem('prov')) {
      $('.field-box').addClass('active')
      const formData = JSON.parse(sessionStorage.getItem('prov'))
      this.occupationControl = formData.noc_label
      this.provinceControl = `${formData.cityName} ${formData.provCode}`
      this.nocFormData = formData
    }
    $(document).ready(function () {
      $(document).on(
        'keyup change paste cut focus',
        '.formControl',
        function () {
          const tmpval = $(this).val();
          if (tmpval == '' || tmpval) {
            $(this).parent().addClass('active').removeClass('invalid');
          } else {
            $(this).parent().removeClass('active').addClass('invalid');
          }
        }
      );
      $('.formControl').blur(function () {
        var $this = $(this);
        if ($this.val()) {
          $this.parent().addClass('active');
        } else {
          $this.parent().removeClass('active');
        }
      });
    });
  }

  onKeyUp(event: any) {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.getNocCodes(event)
    }, 300);
  }

  onKeyDown() {
    clearTimeout(this.typingTimer)
  }

  getNocCodes(e) {
    if (e.target.value.length > 1) {
      const body = { keyword: e.target.value }
      this.http
        .get(
          `https://espbcnocm30-wsx-appservice.azurewebsites.net/search/noc/?lang=en&q=${e.target.value}`,
          { headers: this.reqHeader() }
        )
        .subscribe((res: any) => {
          this.nocArr = res.slice(0, 7);
        });
    } else {
      this.nocArr = [];
    }
  }

  onClickNoc(noc) {
    this.occupationControl = noc.label;
    this.noc = noc.refkey
    this.nocArr.length = 0
  }
  
  onKeyCityUp(event: any) {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.getPlaces(event)
    }, 300);
  }

  onKeyCityDown() {
    clearTimeout(this.typingTimer)
  }

  onClickCity(city) {
    this.provinceControl = city.label;
    this.province = city
    this.geoArr.length = 0
  }

  getPlaces(e) {
    if (e.target.value.length > 1) {
      this.http
        .get(
          'https://espbcnocm30-wsx-appservice.azurewebsites.net/search/city?lang=en&q='+e.target.value,
          {},
          // { headers: this.reqHeader() }
        )
        .subscribe((res: any) => {
          // debugger;
          this.geoArr = res.slice(0, 4);
        });
    } else {
      this.geoArr = [];
    }
  }

  explore() {
    if(!this.occupationControl) {
      document.querySelector('.popup').classList.add('active');
    }
    if (this.province || this.nocFormData) {
      let url
      if(this.province) {
        url = 'https://espbcnocm30-wsx-appservice.azurewebsites.net/gis/latlng/info?pt=' + this.province.latLng
      } else {
        url = 'https://espbcnocm30-wsx-appservice.azurewebsites.net/gis/latlng/info?pt=' + this.nocFormData.latLng
      }
      this.http
        .get(
          url,
          {},
          // { headers: this.reqHeader() }
        )
        .subscribe((res: any) => {
          const data = res
          data['noc'] = this.noc ? this.noc : this.nocFormData.noc
          data['noc_label'] = this.occupationControl
          sessionStorage.setItem('prov', JSON.stringify(data))
          this.router.navigate(['/lmi-results'])
        });
    } else {
      document.querySelector('.popup').classList.add('active');
    }
  }

  closePopup() {
    document.querySelector('.popup').classList.remove('active');
  }


}
