<div class="content-wrapper">
  <div
    class="resources-page sub-category-page"
    style="height: 100%"
    *ngIf="!loading"
  >
    <app-video-resource
      *ngIf="topic.type == 'Video'"
      [resource]="topic"
      [type]="'resource'"
    ></app-video-resource>

    <div
      *ngIf="topic.type == 'Document' && !openingDocument"
      style="height: 100%"
    >
      <iframe
        *ngIf="docLink"
        [src]="docLink"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- <a
      class="download-btn"
      [href]="topic.link"
      [download]="topic.topic"
      [disabled]="dwld.busy"
      title="download {{ topic.topic }}"
      #dwld="wmDownload"
    >
      <img src="assets/img/icon-download.svg" alt="" />
      Download
    </a> -->
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex flex-column align-items-center justify-content-center"
    style="background-color: #fff"
    *ngIf="openingDocument"
  >
    <p class="opening">
      Opening Document<span class="ml-3">.</span><span>.</span><span>.</span>
    </p>
    <p class="text-center mx-3">
      In order to come back to the resources, you need to tap on the back button
      of the device.
    </p>
  </div>
</div>
