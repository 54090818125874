<div class="content-wrapper">
  <div class="container">
    <div class="form-section edit-profile">
      <div class="inner-wrap">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
          <div class="user-img">
            <input
              type="file"
              #profilePic
              style="display: none"
              (change)="readURL($event)"
            />
            <img
              src=""
              [src]="imageSrc || 'assets/img/user.jpg'"
              alt=""
              (click)="profilePic.click()"
            />
            <span class="icon-edit" (click)="profilePic.click()"></span>
          </div>
          <div class="field">
            <div class="field-box active">
              <input
                type="text"
                formControlName="first_name"
                name="first-name"
                id="first-name"
                class="formControl"
              />
            </div>
            <label for="first-name">First Name</label>
          </div>
          <div class="field">
            <div class="field-box active">
              <input
                type="text"
                formControlName="last_name"
                name="last-name"
                id="last-name"
                class="formControl"
              />
            </div>
            <label for="last-name">Last Name</label>
          </div>
          <div class="field">
            <div class="field-box active">
              <input
                type="email"
                formControlName="email"
                name="email"
                id="email"
                class="formControl"
              />
              <span class="icon-email"></span>
            </div>
            <label for="email">Email</label>
          </div>
          <div class="field">
            <div class="field-box active">
              <input
                type="tel"
                formControlName="mobile_number"
                name="phone"
                id="phone"
                class="formControl"
              />
              <span class="icon-phone"></span>
            </div>
            <label for="phone">Mobile Number</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="password"
                formControlName="password"
                name="password"
                id="password"
                class="formControl field-password"
              />
              <span class="icon-password" id="icon-password"></span>
            </div>
            <label for="password">Password</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="password"
                formControlName="password_confirmation"
                name="confirm-password"
                id="confirm-password"
                class="formControl field-password"
              />
              <span class="icon-password" id="icon-confirm-password"></span>
            </div>
            <label for="confirm-password">Confirm Password</label>
          </div>
          <button type="submit" class="btn">Save</button>
          <div class="other-link">
            <p><a routerLink="/home" class="link-cancel">Cancel</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    style="background-color: #fff"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
