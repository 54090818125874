<div class="content-wrapper">
  <div class="congratulations-page" *ngIf="!loading">
    <span class="icon-tick"></span>
    <h2>Congratulations</h2>
    <p *ngIf="selectedDay < 10 && remaining_days.length > 0">
      You have completed
      {{ selectedDay == currentDay ? "today's" : "day " + selectedDay }}
      activities and have earned <strong>{{ points }} points</strong>.
    </p>
    <p *ngIf="(selectedDay == 10 || (selectedDay < 10 && remaining_days.length == 0 ))">
      You have completed the game and have earned a total of <strong>{{ total_points }}</strong> points  for the entire game.
    </p>
    <a
      [routerLink]="['/game', remaining_days[0]]"
      replaceUrl
      class="btn mt-5"
      *ngIf="selectedDay == 10 && remaining_days.length > 0"
      >Review incompleted activities.</a
    >
    <a
      [routerLink]="['/game', remaining_days[0]]"
      replaceUrl
      class="btn mt-5"
      *ngIf="selectedDay < 10 && remaining_days.length > 0 && dayAlreadyOpen"
      >Proceed to Day {{ remaining_days[0] }} activities.</a
    >
    <p class="btn mt-5" *ngIf="remaining_days.length == 0" (click)="resetGame()">
      Reset Game
    </p>
    <p class="info" *ngIf="selectedDay < 10 && remaining_days.length > 0 && !dayAlreadyOpen">
      Your next activities will be available tomorrow
    </p>
  </div>

  <div class="popup">
    <div class="popup-box">
      <p class="mb-5 text-center">
        Your game has besn reset successfully.
      </p>
      <div class="action">
        <button class="btn btn-yes my-2" type="button" (click)="closePopup()">
          Proceed
        </button>
      </div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
