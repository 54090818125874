import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  questionsComplete: boolean = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService,
    private locationStrategy: LocationStrategy,
    private menu: MenuService
  ) {
    this.preventBackButton();
    this.menu.updateTitle('');
  }

  ngOnInit() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/screen_skip',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          if (res.question_attempt) this.questionsComplete = true;
        }
      });
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      if (this.router.url == '/home')
        history.pushState(null, null, location.href);
    });
  }
}
