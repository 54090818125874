import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { error } from 'logrocket';
@Component({
  selector: 'app-lmi-results',
  templateUrl: './lmi-results.component.html',
  styleUrls: ['./lmi-results.component.css'],
})
export class LmiResultsComponent implements OnInit {
  screen: string = 'outlooks';
  example: string = ``
  description: string = ``
  provinesMapper: object = {
    "10": { name: "NL", lat: 53.000000, lng:-60.000000, fullName: "Newfoundland and Labrador"},
    "11": { name: "PE", lat: 46.250000, lng:-63.000000, fullName: "Prince Edward Island"},
    "12": { name: "NS", lat: 45.000000, lng:-63.000000, fullName: "Nova Scotia"},
    "13": { name: "NB", lat: 46.498390, lng:-66.159668, fullName: "New Brunswick"},
    "24": { name: "QC", lat: 53.000000, lng:-70.000000, fullName: "Quebec"},
    "35": { name: "ON", lat: 50.000000, lng:-85.000000, fullName: "Ontario"},
    "46": { name: "MB", lat: 56.415211, lng:-98.739075, fullName: "Manitoba"},
    "47": { name: "SK", lat: 55.000000, lng:-106.000000, fullName: "Saskatchewan"},
    "48": { name: "AB", lat: 55.000000, lng:-115.000000, fullName: "Alberta"},
    "59": { name: "BC", lat: 53.726669, lng:-127.647621, fullName: "British Columbia"},
    "60": { name: "NT", lat: 62.453972, lng:-114.371788, fullName: "Northwest Territories"},
    "61": { name: "YT", lat: 64.000000, lng: -135.000000, fullName: "Yukon"},
    "62": { name: "NU", lat: 70.453262, lng:-86.798981, fullName: "Nunavut"}
  }
  provinceData: any
  lmiPotential: any
  zoom: number = 8;
  
  // initial center position for the map
  lat: number = 43.651070;
  lng: number = -79.347015;
  public markers = []

  public markerDescripton = ''
  public pointedMarker: any = undefined
  public markerStar: number = 0
  public outLook = ''
  public relatedNocResults = {}

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
  ) { }

  ngOnInit(): void {
    const provData = sessionStorage.getItem('prov')
    this.provinceData = JSON.parse(provData)
    console.log(this.provinceData)
    this.getNocData()
    this.getLmiTrends()
    this.getLmiRelatedData()
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  reqHeadersWithLMI(key) {
    return new HttpHeaders({
      'user-key': key,
    });
  }

  openPopup() {
    let popup = document.querySelector('.popup');
    popup.classList.add('active');
  }

  openOutLookPopup() {
    let popup = document.querySelector('.outlook-info');
    popup.classList.add('active');
  }

  closeMenu() {
    let popup = document.querySelector('.popup');
    popup.classList.remove('active');
  }

  closeMenuOutlook() {
    let popup = document.querySelector('.outlook-info');
    popup.classList.remove('active');
  }

  getNocData() {
    this.http
    .get(
      `https://lmi-outlooks-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/gcapis/outlooks/ca?noc=${this.provinceData.noc}`,
      // {},
      { headers: this.reqHeadersWithLMI('62ad4d13ee62d2f40432386356190225') }
    )
    .subscribe((res: any) => {
      // debugger;
      console.log(res);
      const province = []
      res.forEach(data => {
        if(data.potential === 1) {
          data['star'] = 3
          province.push(data)
        } else if(data.potential === 2) {
          data['star'] = 1
          province.push(data)
        } else if(data.potential === 3) {
          data['star'] = 2
          province.push(data)
        } else {
          data['star'] = 0
          province.push(data)
        }
      })
      this.lmiPotential = province
    });
  }

  getLmiTrends() {
    this.http
    .get(
      `https://lmi-outlooks-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/gcapis/outlooks?lang=en&noc=${this.provinceData.noc}&rtp=2&rid=${this.provinceData.eruID}`,
      // {},
      { headers: this.reqHeadersWithLMI('d3bdf438a7a3024b851fee18e9dc908d') }
    )
    .subscribe((res: any) => {
      const pTags = []
      res.trends.replace(/<p>(.*?)<\/p>/g, (match) => {
        pTags.push(match) 
      })
      const line =  pTags[0].replace(/<p>/g, '')
      this.example = line.replace(/<\/p>/g, '')
      this.description = res.trends
    })
  }

  getLmiRelatedData() {
    this.http
    .get(
      `https://espbcnocm30-wsx-appservice.azurewebsites.net/noc/title/info?lang=en&noc=${this.provinceData.noc}`,
      // {},
      { headers: this.reqHeadersWithLMI('d3bdf438a7a3024b851fee18e9dc908d') }
    )
    .subscribe((res: any) => {
      console.log(res)
      const regEx4Match = new RegExp(/\d{4}(?!\d)/)
      const regEx3Match = new RegExp(/\d{3}(?!\d)/)
      const relatedNoc = {}
      res.relatedOccupations.forEach(occu => {
        let noc = occu.match(regEx4Match)
        if(!noc) {
          noc = occu.match(regEx3Match)
        }
        relatedNoc[occu.split('(')[0].trim()] = noc[0]
      })
      this.getRelatedNocData(relatedNoc)
    })
  }

  getRelatedNocData(nocObject) {
    Object.keys(nocObject).forEach(data => {
      this.relatedNocResults[data] = {}
      this.http.get(
        `https://lmi-outlooks-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/gcapis/outlooks?lang=en&noc=${nocObject[data]}&rtp=1&rid=${this.provinceData.provId}`,
        // {},
        { headers: this.reqHeadersWithLMI('d3bdf438a7a3024b851fee18e9dc908d') }
      )
      .subscribe((res: any) => {
        let star = 0
        if(res.potential === 1) {
          star = 3
        } else if(res.potential === 2) {
          star = 1
        } else if(res.potential === 3) {
          star = 2
        } else {
          star = 0
        }
        this.relatedNocResults[data]['provincial'] = star
        this.relatedNocResults[data]['province'] = this.provinceData.ptName
      })

      this.http.get(
        `https://lmi-outlooks-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/gcapis/outlooks?lang=en&noc=${nocObject[data]}&rtp=2&rid=${this.provinceData.eruID}`,
        // {},
        { headers: this.reqHeadersWithLMI('d3bdf438a7a3024b851fee18e9dc908d') }
      )
      .subscribe((res: any) => {
        let star = 0
        if(res.potential === 1) {
          star = 3
        } else if(res.potential === 2) {
          star = 1
        } else if(res.potential === 3) {
          star = 2
        } else {
          star = 0
        }
        this.relatedNocResults[data]['reginal'] = star
        this.relatedNocResults[data]['region'] = this.provinceData.erName
      })
    })
  }

  showMap() {
    Object.keys(this.provinesMapper).forEach(prov => {
      const {name, ...latLong} = this.provinesMapper[prov]
      latLong['draggable'] = false
      latLong['label'] = name
      latLong['provId'] = prov
      latLong['erName'] = this.provinceData['erName']
      latLong['provCode'] = this.provinceData['provCode']
      if(this.provinceData.provId.toString() === prov) {
        console.log(latLong)
        latLong['lat'] = parseFloat(this.provinceData.latLng.split(',')[0])
        latLong['lng'] = parseFloat(this.provinceData.latLng.split(',')[1])
        this.pointedMarker = latLong
      }
      this.markers.push(latLong)
    })
    this.screen = 'maps'
    this.lat = parseFloat(this.provinceData.latLng.split(',')[0])
    this.lng = parseFloat(this.provinceData.latLng.split(',')[1])
  }

  markerClicked(marker) {
    this.pointedMarker = marker
    const nocId = this.provinceData.noc
    this.http
    .get(
      `https://lmi-outlooks-esdc-edsc-apicast-production.api.canada.ca/clmix-wsx/gcapis/outlooks?lang=en&noc=${nocId}&rtp=2&rid=${marker.provId}`,
      // {},
      { headers: this.reqHeadersWithLMI('d3bdf438a7a3024b851fee18e9dc908d') }
    )
    .subscribe((res: any) => {
      console.log(res)
      if(Object.keys(res).length > 0) {
        this.markerDescripton = res.trends
        if(res.potential === 1) {
          this.markerStar = 3
          this.outLook = "Good"
        } else if(res.potential === 2) {
          this.markerStar = 1
          this.outLook = "Limited"
        } else if(res.potential === 3) {
          this.markerStar = 2
          this.outLook = "Fair"
        } else {
          this.markerStar = 0
          this.outLook = "Undetermined"
        }
      } else {
        this.markerStar = 0
        this.markerDescripton = 'N/A'
        this.outLook = "Undetermined"
      }
    })
  }

  ngAfterViewInit() { }
}
