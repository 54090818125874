<div class="content-wrapper">
  <div class="game-home" *ngIf="!loading">
    <div class="img">
      <img src="../../assets/img/game-home.png" alt="" />
    </div>

    <h3>To start playing, you must select an industry/occupation</h3>

    <div class="game-dropdown">
      <select
        name="games-list"
        id="game"
        class="game-list"
        data-placeholder="Select your industry/occupation"
      >
        <option selected disabled value="">
          Select your industry/occupation
        </option>
        <option *ngFor="let industry of industries" [value]="industry.id">
          {{ industry.industry_name }}
        </option>
      </select>
    </div>

    <div class="game-block">
      <h4>Game</h4>
      <a (click)="openGame(null)" class="btn mb-5">Play Game</a>
      <ul>
        <li
          *ngFor="let item of days"
          [ngClass]="{ locked: item.attempt == 'no' }"
        >
          <div class="day-box">
            <div class="gradient-box" (click)="openGame(item)">
              <span class="day-count">{{ item.day }} <em>Day</em></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="popup">
    <div class="popup-box">
      <span class="close"></span>

      <h5>Local Employment Potential Information</h5>
      <p>
        The employment outlook will be fair for Retail salespersons (NOC 6421)
        in the Muskoka - Kawarthas region for the 2019-2021 period.
      </p>
      <p>The following factors contributed to this outlook:</p>
      <ul class="listing-dotted">
        <li>
          Employment growth will lead to a moderate number of new positions.
        </li>
        <li>Several positions will become available due to retirements.</li>
        <li>
          There are a small number of unemployed workers with recent experience
          in this occupation.
        </li>
      </ul>
      <p>
        Tourism activities are significant in this region which should add
        opportunities for retail salespersons, especially in the summer.
      </p>
      <p>
        Here are some key facts about Financial auditors and accountants in the
        Muskoka - Kawarthas region:
      </p>
      <ul class="listing-dotted">
        <li>Approximately 5,330 people work in this occupation.</li>
        <li>
          Retail salesperson mainly work in this occupation:
          <ul>
            <li>Retail Trade(NAICS 44-45): 83%</li>
            <li>Wholesale trade (NAICS 41): 5%</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>

  <!-- <div
    class="scroll-down-btn"
    [ngStyle]="{ display: scrollBtn() ? 'block' : 'none' }"
  >
    <span></span>
    <span></span>
    <span></span>
  </div> -->
</div>
