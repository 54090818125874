import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import { TrackService } from 'src/app/services/track.service';
import { Swiper } from 'swiper';
import { isRegExp } from 'util';

@Component({
  selector: 'app-previous-day-activity',
  templateUrl: './previous-day-activity.component.html',
  styleUrls: ['./previous-day-activity.component.css'],
})
export class PreviousDayActivityComponent implements OnInit {
  _activities: any = [];
  reset: boolean = false;
  public day: any;
  selectedActivity: any;
  constructor(
    private menuService: MenuService,
    private http: HttpClient,
    private cookie: CookieService,
    private trackService: TrackService,
    private router: Router,
    private _route: ActivatedRoute
  ) { 
    this._route.params.subscribe((params) => {
      this.day = params.day;
      this.timeTrack();
    });
  }

  ngOnInit(): void {
    // debugger;
  

    // let local = JSON.parse(localStorage.getItem('stamp'));
    // if (local.z === 20) {
    //   localStorage.setItem('stamp', JSON.stringify({date: new Date(), z: 10}))
    //    location.reload();
    // }
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  openResource(item) {
    console.log(item)
    this.selectedActivity = item;
    if (item.type == 'Video' || item.type == 'Document') {
      this.markAsRead(null);
      localStorage.setItem('stamp', JSON.stringify({date: new Date(), z: 20}))
      this.router.navigate(['/activity', item.id]);
    } else {
      this.openPopup();
    }
  }

  openPopup() {
    document.querySelector('.popup').classList.add('active');
  }

  closePopup() {
    document.querySelector('.popup').classList.remove('active');
  }

  redirect() {
    localStorage.setItem('stamp', JSON.stringify({date: new Date(), z: 20}))
    this.markAsRead('website');


    this.trackService.isRunning = true;

  }
  markAsRead(type) {
    this.http
      .post(
        'https://admin.careerforward.ca/api/save_activity_response',
        {
          question_id: this.selectedActivity.question_id,
          id: this.selectedActivity.id,
          activity_id: this.selectedActivity.activity_id,
          response: 'yes',
          day: this.day,
        },
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        if (res.status) {
          if (type == 'website') {
            this.closePopup();

            window.location.href = this.selectedActivity.detail;

          }
          //  this.menuService.updateCoins(res.points);
        }
      });

  }

  timeTrack() {

    let params = new HttpParams();
    params = params.append('key', '');
    params = params.append('value', 'a');
    params = params.append('disabled', 'true');
    this.http
      .post(
        'https://admin.careerforward.ca/api/saveActivity_read_response',
        { day: this.day },
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        if (res.status) {
          // debugger;
          console.log(res);
          let allComplete = true
          this.menuService.updateCoins(res.points);
          res.data.forEach((e) => {
            if (!e.read_status) {
              allComplete = false
            }
          });
          if (allComplete) {
            if (res.redirection) {
              this.router.navigate([
                '/activities-success',
                this.day,
              ]);
            }
          }
          this.reset = allComplete;
          this._activities = res.data
        }
      })
  }

  resetGame() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/reset_game',
        {},
        { headers: this.reqHeaders() }
      )
      .subscribe((res: any) => {
        console.log(res);
        this.menuService.updateCoins(0);
        if (res.status) {
          this.openResetPopup()
        }
      });
  }

  openResetPopup() {
    document.querySelector('.popup-reset').classList.add('active');
  }

  closeResetPopup() {
    document.querySelector('.popup-reset').classList.remove('active');
    this.router.navigate(['/home']);
  }

  // scrollBtn() {
  //   let div1, div2;
  //   try {
  //     div1 = window
  //       .getComputedStyle(document.querySelector('.white-box'), null)
  //       .getPropertyValue('height');
  //   } catch (e) {
  //     div1 = (<any>document.querySelector('.white-box')).currentStyle.height;
  //   }
  //   try {
  //     div2 = window
  //       .getComputedStyle(document.querySelector('.activity-wrapper'), null)
  //       .getPropertyValue('height');
  //   } catch (e) {
  //     div2 = (<any>document.querySelector('.activity-wrapper')).currentStyle
  //       .height;
  //   }

  //   // console.log(div2, div1);
  //   if (parseInt(div2, 10) > parseInt(div1, 10)) {
  //     if (document.querySelector('.activity-wrapper').scrollTop < 10) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // ngAfterViewInit() {
  //   var swiper = new Swiper('.swiper-container', {
  //     slidesPerView: 7,
  //     centeredSlides: true,
  //     freeMode: true,
  //   });
  //   swiper.slideTo(new Date().getDate() - 1, 0, false);

  //   document.addEventListener('scroll', () => {
  //     // console.log(window.pageYOffset);
  //   });
  // }
}
