<div class="content-wrapper">
  <div class="header small">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="" width="270" />
    </div>
  </div>
  <div class="container">
    <div class="form-section signup">
      <div class="inner-wrap">
        <form enctype="multipart/form-data" onsubmit="javascript:return false;">
          <div class="field">
            <div class="field-box">
              <input
                type="text"
                name="first-name"
                id="first-name"
                class="formControl"
              />
            </div>
            <label for="first-name">First Name</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="text"
                name="last-name"
                id="last-name"
                class="formControl"
              />
            </div>
            <label for="last-name">Last Name</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input type="email" name="email" id="email" class="formControl" />
              <span class="icon-email"></span>
            </div>
            <label for="email">Email</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="tel"
                name="phone"
                id="phone"
                class="formControl"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                maxlength="14"
              />
              <span class="icon-phone"></span>
            </div>
            <label for="phone">Mobile Number</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="password"
                name="password"
                id="password"
                #passwordInput
                class="formControl field-password"
              />
              <p class="hint">Must be 8 characters long</p>
              <span
                class="icon-password"
                id="icon-password"
                (click)="togglePassword()"
              ></span>
            </div>
            <label for="password">Password</label>
          </div>
          <div class="field">
            <div class="field-box">
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                class="formControl field-password"
              />
              <span
                class="icon-password"
                id="icon-confirm-password"
                (click)="toggleConfirmPassword()"
              ></span>
            </div>
            <label for="confirm-password">Confirm Password</label>
          </div>
          <div class="checkbox">
            <input type="checkbox" name="t-and-c" id="t-and-c" />
            <label for="t-and-c"
              >I agree to
              <a href="javascript:void(0);">terms & conditions</a></label
            >
          </div>
          <button type="submit" class="btn" (click)="signupForm()">
            Sign Up
          </button>
          <div class="other-link">
            <p>Already have an account? <a routerLink="/login">Sign In</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="content-wrapper d-flex align-items-center justify-content-center"
    style="background-color: #fff"
    *ngIf="loading"
  >
    <div class="spinner">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</div>
