import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubject = new BehaviorSubject(
    this.cookie.check('user') ? JSON.parse(this.cookie.get('user')) : null
  );
  user = this.userSubject.asObservable();
  updateUserDetails(value) {
    this.userSubject.next(value);
  }

  constructor(private cookie: CookieService, private http: HttpClient) {}

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  logout() {
    return this.http.post(
      'https://admin.careerforward.ca/api/logout',
      {},
      { headers: this.reqHeaders() }
    );
  }
}
