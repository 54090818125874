import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { ActivitiesSuccessComponent } from './pages/activities-success/activities-success.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CareerDevelopmentSubcategoryComponent } from './pages/career-development-subcategory/career-development-subcategory.component';
import { CareerDevelopmentComponent } from './pages/career-development/career-development.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { GameConfirmationComponent } from './pages/game-confirmation/game-confirmation.component';
import { GameHomeComponent } from './pages/game-home/game-home.component';
import { GameRulesComponent } from './pages/game-rules/game-rules.component';
import { HomeComponent } from './pages/home/home.component';
import { IndeedJobSearchComponent } from './pages/indeed-job-search/indeed-job-search.component';
import { IntroComponent } from './pages/intro/intro.component';
import { JobSearchSubcategoryComponent } from './pages/job-search-subcategory/job-search-subcategory.component';
import { JobSearchComponent } from './pages/job-search/job-search.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { LmiFormComponent } from './pages/lmi-form/lmi-form.component';
import { LmiResultsComponent } from './pages/lmi-results/lmi-results.component';
import { LoginComponent } from './pages/login/login.component';
import { NeedHelpComponent } from './pages/need-help/need-help.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { QuestionsSuccessComponent } from './pages/questions-success/questions-success.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { SuccessMessageComponent } from './pages/success-message/success-message.component';
import { DisruptorsComponent } from './pages/disruptors/disruptors.component';
import { ContinueDisruptorComponent } from './pages/continue-disruptor/continue-disruptor.component';
import { VideoResourceComponent } from './pages/video-resource/video-resource.component';
import { ResourceItemComponent } from './pages/resource-item/resource-item.component';
import { ActivityItemComponent } from './pages/activity-item/activity-item.component';
import { DisruptorContentComponent } from './pages/disruptor-content/disruptor-content.component';
import { JobSearchResultsComponent } from './pages/job-search-results/job-search-results.component';

const routes: Routes = [
  {
    path: 'splash-screen',
    component: SplashScreenComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'intro',
    component: IntroComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: SideMenuComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game-home',
        component: GameHomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game-rules',
        component: GameRulesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game-rules/:day',
        component: GameRulesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'leaderboard',
        component: LeaderboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game-confirmation',
        component: GameConfirmationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game-confirmation/:day',
        component: GameConfirmationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game',
        component: CalendarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'game/:day',
        component: CalendarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'continue-disruptor/:day',
        component: ContinueDisruptorComponent,
      },
      {
        path: 'questions-success/:day',
        component: QuestionsSuccessComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'activity/:id',
        component: ActivityItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'activities-success/:day',
        component: ActivitiesSuccessComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'announcement/:id',
        component: AnnouncementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'career-development',
        component: CareerDevelopmentComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'career-development/:id',
        component: CareerDevelopmentSubcategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'faq',
        component: FaqComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'job-search',
        component: JobSearchComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'job-search/:id',
        component: JobSearchSubcategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'resource/:id',
        component: ResourceItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'lmi-form',
        component: LmiFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'lmi-results',
        component: LmiResultsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'need-help',
        component: NeedHelpComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'disruptors',
        component: DisruptorsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'disruptor-content/:id',
        component: DisruptorContentComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'success-message',
        component: SuccessMessageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'job-search-form',
        component: IndeedJobSearchComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'job-search-results',
        component: JobSearchResultsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        redirectTo: '/splash-screen',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '/splash-screen',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
