import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';
import { Swiper } from 'swiper';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  dates: any[] = [];
  selectedDay: any;
  loading: boolean = true;
  startDate: any;
  currentDay: number;
  screen: any;
  data: any;
  swiper: Swiper;
  remaining_day: any;
  coins: any = 0;

  constructor(
    private menuService: MenuService,
    private cookie: CookieService,
    private http: HttpClient,
    private _route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menuService.coins.subscribe((coinsCount) => {
      if (coinsCount) {
        this.coins = coinsCount;
      } else {
        this.coins = 0;
      }
    });

    this._route.params.subscribe((params) => {
      this.selectedDay = params.day;
      this.loading = true;
      this.loadDates();
    });
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadDates() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/day_tracking',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        if (res.status) {
          console.log(res);
          if (res.date) {
            this.startDate = moment(res.date);
          } else {
            this.startDate = new Date();
          }
          var days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          res.data.forEach((element) => {
            if (element.attempt == 'current') {
              this.currentDay = element.day;
            }
          });
          if (!this.selectedDay || this.selectedDay > this.currentDay) {
            this.router.navigate(['/game', this.currentDay]);
            return;
          }
          if (this.dates.length == 0) {
            let i = 0;
            let count = 0;
            while (count < 10) {
              let tempDate = moment(this.startDate).add(i, 'days')
              let tempDay = days[moment(tempDate).format('d')][0];
              if (tempDay != 'S') {
                this.dates.push({
                  date: moment(tempDate).format('D'),
                  day: tempDay,
                  gameDay: res.data[count].day,
                });
                count++;
              } else {
                this.dates.push({
                  date: moment(tempDate).format('D'),
                  day: tempDay,
                  tooltip: true,
                });
              }
              i++;
            }
            console.log(this.dates);
            setTimeout(() => {
              this.swiper = new Swiper('.swiper-container', {
                slidesPerView: 7,
                centeredSlides: true,
                freeMode: true,
              });
              this.swiper.slideTo(this.selectedDay - 1, 0, false);
            }, 0);
          }
          this.loadData();
        }
      });
  }

  loadData() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/questions',
        { day: this.selectedDay },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.menuService.updateCoins(res.points);
          if (res.disruptor_status) {
            this.router.navigate(['/continue-disruptor', res.disruptor_day]);
          } else {
            this.screen = res.type;
            this.data = res.data;
            if (res.type == 'activity') {
              let all_complete: boolean = true;
              res.data.forEach((e) => {
                if (!e.read_status) {
                  all_complete = false;
                }
              });
              if (all_complete) {
                if (res.redirection) {
                  this.router.navigate([
                    '/activities-success',
                    this.selectedDay,
                  ]);
                }
              }
            }
            this.loading = false;
            if (res.type == 'question') {
              if (res.remaining_days.length > 0) {
                this.remaining_day = Math.min.apply(Math, res.remaining_days);
                document
                  .querySelector('.popup-prev-game')
                  .classList.add('active');
              }
            }
          }
        }
      });
  }

  continuePrevGame() {
    document.querySelector('.popup-prev-game').classList.remove('active');
    this.router.navigate(['/game', this.remaining_day]);
  }

  closePopup() {
    document.querySelector('.popup-prev-game').classList.remove('active');
  }

  loadGameDataForDay(gameDay) {
    if (gameDay) {
      if (gameDay <= this.currentDay) {
        this.selectedDay = gameDay;
        this.router.navigate(['/game', this.selectedDay], { replaceUrl: true });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Game Not Played on Saturdays and Sundays.',
      });
    }
  }

  // moveToSlide(i) {
  //   this.swiper.slideTo(i, 0, false);
  // }

  openMenu() {
    this.menuService.updateMenuOpenStatus(true);
  }
}
