import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css'],
})
export class LeaderboardComponent implements OnInit {
  users: any = [];
  myDetails: any;
  loading: boolean = true;
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('');
  }

  ngOnInit(): void {
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/leaderboard',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.users = res.data;
          this.myDetails = res.user_data;
          res.data.forEach((user, index) => {
            if (user.id == this.myDetails.id) {
              this.myDetails.rank = user.rank;
            }
          });
          this.loading = false;
        }
      });
  }

  updateUrl(e) {
    e.target.src = 'assets/img/user.jpg';
  }
}
