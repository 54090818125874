import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.css'],
})
export class ActivityItemComponent implements OnInit {
  id: any;
  loading: boolean = false;
  activity: any;
  docLink: any;
  openingDocument: boolean = false;
  constructor(
    private http: HttpClient,
    private _route: ActivatedRoute,
    private cookie: CookieService,
    private menu: MenuService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.id = this._route.snapshot.params.id;
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    this.loading = true;
    this.http
      .post(
        'https://admin.careerforward.ca/api/activity_detail',
        { id: this.id },
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.activity = res.data[0];
          if (this.activity.type == 'Video') {
            this.menu.updateTitle('WATCH THE VIDEO');
            console.log(this.activity);
          } else if (this.activity.type == 'Document') {
            this.menu.updateTitle('');
            this.openingDocument = true;
            this.docLink = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.activity.detail
            );
            setTimeout(() => {
              this.openingDocument = false;
              this.menu.updateTitle('READ CONTENT');
            }, 4000);
          } else {
            // NO RESOURCE FOUND
          }
        }
        this.loading = false;
      });
  }
}
