import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from 'src/app/services/menu.service';
import $ from 'jquery';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  new_notifs: any = [];
  past_notifs: any = [];
  announcement: any;

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private menu: MenuService
  ) {
    this.menu.updateTitle('NOTIFICATIONS');
  }

  ngOnInit() {
    this.loadData();
  }

  reqHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get('token')}`,
    });
  }

  loadData() {
    this.new_notifs = [];
    this.past_notifs = [];
    this.http
      .post(
        'https://admin.careerforward.ca/api/notifications',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        res.data.forEach((notif) => {
          if (notif.status == 'active') this.new_notifs.push(notif);
          else this.past_notifs.push(notif);
        });
        this.http
          .post(
            'https://admin.careerforward.ca/api/read_notification',
            {},
            {
              headers: this.reqHeaders(),
            }
          )
          .subscribe((res) => {
            this.menu.updateClearAllNotifs(true);
          });
      });
    this.http
      .post(
        'https://admin.careerforward.ca/api/announcement',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        this.announcement = res.data[0];
        this.announcement.desc = $('<div>')
          .html(this.announcement.description)
          .text();
      });
  }

  clearAllNotifs() {
    this.http
      .post(
        'https://admin.careerforward.ca/api/clear_notification',
        {},
        {
          headers: this.reqHeaders(),
        }
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          this.past_notifs = [];
          this.new_notifs = [];
        }
      });
  }
}
