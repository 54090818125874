<div class="content-wrapper">
  <div class="container">
    <div class="form-section lmi">
      <div class="inner-wrap">
        <form action="">
          <div class="field">
            <div class="field-box position-relative">

              <input
                type="text"
                name="occupation"
                id="occupation"
                #occupation
                [(ngModel)]="occupationControl"
                class="formControl"
                (keyup)="onKeyUp($event)" (keydown)="onKeyDown()"
              />

              <!-- <span class="icon-user"></span> -->
              <span class="noc-box">NOC</span>
              <div
                class="noc position-absolute"
                *ngIf="occupation.value.length > 1 && nocArr.length > 0"
              >
                <div class="noc-item" (click)="onClickNoc(item)" *ngFor="let item of nocArr">
                  {{ item.label }}
                </div>
              </div>
            </div>
            <label for="occupation">Occupation</label>
          </div>
          <div class="field">
            <div class="field-box position-relative">
              <input
                type="text"
                name="geographic"
                id="geographic"
                class="formControl"
                [(ngModel)]="provinceControl"
                #geographic
                (keyup)="onKeyCityUp($event)" (keydown)="onKeyCityDown()"
              />
              <span class="icon-location"></span>

              <div
                class="geo position-absolute"
                *ngIf="geographic.value.length > 1 && geoArr.length > 0"
              >
                <div class="geo-item" (click)="onClickCity(item)" *ngFor="let item of geoArr">
                  {{ item.label }}
                </div>
              </div>
            </div>
            <label for="geographic">Geographic Area</label>
          </div>
          <button type="submit" class="btn" (click)="explore()">Explore</button>
        </form>
      </div>
    </div>
    <!-- <div
      *ngIf="comingSoon"
      class="d-flex flex-column align-items-center justify-content-center comingSoon text-center"
      style="height: 100%"
    >
      <h2 class="mb-4">Coming Soon</h2>
      <p>Employement Outlooks will be available soon...</p>
    </div> -->
  </div>
  <div class="popup">
    <div class="popup-box">
      <p class="mb-5 text-center">
        Invalid input.
        Select a item from the dropdown
      </p>
      <div class="action">
        <button class="btn btn-yes my-2" type="button" (click)="closePopup()">
          OK
        </button>
      </div>
    </div>
  </div>

</div>
