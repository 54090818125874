<div class="content-wrapper">
  <div class="page-block center">
    <h2 class="heading-blue mb-5">What do you want to do?</h2>
    <ul class="home-to-do-list">
      <li class="mb-5">
        <a [routerLink]="questionsComplete ? ['/game'] : ['/game-home']">
          <span class="icon icon-play-game"></span>
          <span class="text">Play Game</span>
        </a>
      </li>
      <li class="mb-3">
        <a routerLink="/resources">
          <span class="icon icon-resources"></span>
          <span class="text">Explore Resources</span>
        </a>
      </li>
    </ul>
  </div>

  <script src="../../assets/js/custom.js"></script>
</div>
