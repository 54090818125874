<div class="content-wrapper">
  <div class="faq-page">
    <ul>
      <!-- <li>
        <div class="acc-heading">
          Can I go back and change the answer of previous question again?
        </div>
        <div class="acc-box">
          <p>No! You can not go back and answer the question.</p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          What will be the circumstances if I miss questions for a day?
        </div>
        <div class="acc-box">
          <p>No! You can not go back and answer the question.</p>
        </div>
      </li>
      <li>
        <div class="acc-heading">Can I see the previous day activities?</div>
        <div class="acc-box">
          <p>No! You can not go back and answer the question.</p>
        </div>
      </li>
      <li>
        <div class="acc-heading">Is it necessary to answer the disruptors?</div>
        <div class="acc-box">
          <p>No! You can not go back and answer the question.</p>
        </div>
      </li> -->
      <li>
        <div class="acc-heading">
          Can I jump ahead and finish the game before the 10 days are up?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            No. Each day's questions and activities will only be released as
            that day arrives.
          </p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          What happens if I fall behind? Can I catch up?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            What happens if I fall behind? Can I catch up? You have 10 days to
            complete the game. Once a day's questions and activities are
            unlocked, they will be available for the duration of the game.
          </p>
        </div>
      </li>
      <!-- <li>
        <div class="acc-heading">Is there any way to earn bonus points?</div>
        <div class="acc-box">
          <p></p>
        </div>
      </li> -->
      <li>
        <div class="acc-heading">
          Can I go back and change my response?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            No. But, you also don't gain more points by having the right answer.
            The point of the game is to learn!
          </p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          What if I forget my password? How do I reset it?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>Click on "Forgot password?" and follow the steps.</p>
        </div>
      </li>

      <li>
        <div class="acc-heading">
          How do I get back to a previous screen I was viewing?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            To return to a previous screen, simply tap the back button on your
            phone's browser.
          </p>
        </div>
      </li>

      <li>
        <div class="acc-heading">
          How do I change my profile information and add a photo?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            Tap on the hamburger menu on the top left hand side. The menu will
            slide open and simply tap on your name. This will allow you to
            change your profile information and add a photo.
          </p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          How do I view notifications?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            To view notifications, tap on the bell icon found at the top right
            of the screen.
          </p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          How come I'm not receiveing any questions on the weekends?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>The game is set up to play Monday to Friday only.</p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          After I complete an actiivty, how do I get back to the list of
          activities?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            To return to your activity list, simply tap the back button on your
            phone's browser to return to the list.
          </p>
        </div>
      </li>
      <li>
        <div class="acc-heading">
          What are Resources?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>
            The resource section contains information about conducting a job
            search and managing your career. Simply tap on the resource section
            of the menu and tap on the information you are interested in
            vieiwing.
          </p>
        </div>
      </li>
      <!-- <li>
        <div class="acc-heading">
          What if I forget my password? How do I reset it?
          <div class="acc-icon"></div>
        </div>
        <div class="acc-box">
          <p>Click on "Forgot password?" and follow the steps.</p>
        </div>
      </li> -->
    </ul>
  </div>
</div>
