<div class="content-wrapper">
  <div class="confirmation-block">
    <div class="confirmation-box">
      <span class="icon-circle-game"></span>
      <p>Are you sure you want to start the game?</p>
    </div>
    <a
      [routerLink]="selectedDay ? ['/game', selectedDay] : ['/game']"
      replaceUrl
      class="btn"
      >Proceed</a
    >
  </div>
</div>
