<div class="content-wrapper">
  <div class="announcement-page" *ngIf="!loading">
    <img [src]="announcement.attachment" width="100%" alt="" />
    <h3>{{ announcement.title }}</h3>
    <div [innerHTML]="announcement.description"></div>
  </div>
</div>

<div
  class="content-wrapper d-flex align-items-center justify-content-center"
  style="background-color: #fff"
  *ngIf="loading"
>
  <div class="spinner">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</div>
