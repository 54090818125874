<div class="content-wrapper">
  <div class="header small">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="" width="270" />
    </div>
  </div>
  <div class="container">
    <div class="form-section">
      <div class="inner-wrap">
        <form enctype="multipart/form-data" onsubmit="javascript:return false;">
          <p class="info">
            Please enter your registered email to get verification code
          </p>
          <div class="field mb-75">
            <div class="field-box">
              <input type="email" name="email" id="email" class="formControl" />
              <span class="icon-email"></span>
            </div>
            <label for="email">Email</label>
          </div>
          <button type="submit" class="btn" (click)="forgotPassword()">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>

  <script src="../../assets/js/custom.js"></script>
</div>
